/*
 *  main.js
 *  Solitaire
 *
 *  Created by Kieran Hannagan & Kim Moran on Aug 18, 2022
 *  Copyright © 2022-2023 Sinclair Digital. - All Rights Reserved
 *
 *  This is the main game logic source file.
 */
/* ---------------------------------------------------------------- */

// imports

import '../css/style.css';
import '../css/modal.css';
import { v4 as UUID } from 'uuid';
import * as sol_object from '../../../common/src/SOL_Object.mjs';
import * as sol_images from './SOL_Images';
import * as sol_canvas from './SOL_Canvas';
import * as sol_deck from '../../../common/src/SOL_Deck.mjs';
import * as sol_animation from './SOL_Animation';
import * as sol_update from './SOL_Update';
import * as sol_server from './SOL_Server';
import * as sol_score from '../../../common/src/SOL_Score.mjs';
import * as sol_render from './SOL_Render';
import * as sol_options from '../../../common/src/SOL_Options.mjs';
import { Cards } from '../../../common/src/SOL_Cards.mjs';
import Modal from './SOL_Modal';
import * as sdg_input from '../../libs/SDG_Input';
import * as sol_undo from './SOL_Undo';
import {SOL_ActivatedGameState} from './SOL_ActivatedGameState';

// buttons and dynamic fields in navbar

const NEW = document.querySelector('.new-game-icon');
let TIMER = document.querySelector('.timer');
let POINTS = document.querySelector('.points');
let DESKTOPCARDS = document.querySelector('.desktop-card');
let MOBILECARDS = document.querySelector('.mobile-card');
let ACCESSIBILITYCARDS = document.querySelector('.accessibility-card');
let ACCESSIBILITY1CARDS = document.querySelector('.accessibility1-card');
const EXITGAME = document.querySelectorAll('.exit-game');

const RESETSTATS = document.querySelector('#reset-stats');
const THREECARDDEAL = document.querySelector('#three-card-deal');
const BUTTONCONTAINER = document.querySelector('.button-container');
const UNDO = document.querySelector('.undo-icon');

const statsModalBody = document.querySelector('.stats-modal');
const settingsModalBody = document.querySelector('.settings-modal');
const alertsModalBody = document.querySelector('.alerts-modal');
const timerAndPointsDiv = document.getElementById('timer-and-points');

/* ---------------------------------------------------------------- */

const SOL_Object = new sol_object.SOL_Object(); // Common - game engine, provides immediate game state
const SOL_Options = new sol_options.SOL_Options(SOL_Object);
const SOL_Images = new sol_images.SOL_Images(); // load and retrieve our images
const SOL_Canvas = new sol_canvas.SOL_Canvas(SOL_Images); // defining our canvas and layout based on player screen
// const SOL_Deck = new sol_deck.SOL_Deck(); // Common - creating cards/shuffling/assigning to cards object
const SOL_Score = new sol_score.SOL_Score(SOL_Object.client);
const SOL_Server = new sol_server.SOL_Server();                                                   // contains all server functionality for the client-side
const CARDS = new Cards(SOL_Object.client);                                                       // All the things with cards, kept as CARD since it is a class, not an exported function :)
const SOL_Animation = new sol_animation.SOL_Animation(SOL_Object, SOL_Images, SOL_Canvas, CARDS); // event listeners for touch/mouse, user movement, end movement
const SOL_Update = new sol_update.SOL_Update(SOL_Object.client, SOL_Score, SOL_Animation);        // we will need to render from here, so it comes after render.

const SOL_Render = new sol_render.SOL_Render(SOL_Object, SOL_Images, SOL_Canvas, SOL_Animation);  // renders screen based on SOL_Object

const SDG_Input = new sdg_input.SDG_Input(SOL_Canvas.canvas); // TODO - pass in context to capture input on.
const SOL_Undo = new sol_undo.SOL_Undo(SOL_Object);                                               // All functions containing undo logic

// deconstruct global SOL_OBJECT so we can update variables dynamically here and for the player.
const client = SOL_Object.client;
client.animation = SOL_Animation;

// A store for player data.
let playerData = {};

// declare game constants
// initialize game variables and icons

let timerInterval;

// a promise to load all of the assets and get the game into a game center ready state
let areResourcesLoadedPromise;

// store state and resources to cleanup after an activce game exists
let activatedGameState;

clearInterval(timerInterval);
client.secondsPlayed = 0;

/* ---------------------------------------------------------------- */

// testing contextMenu event prevention on mobile.

window.addEventListener('contextmenu', (event) => {
  event.preventDefault();
});

/* ---------------------------------------------------------------- */

// main modal for settings

const settingsModal = new Modal(
  'settingsModal',
  settingsModalBody,
  document.querySelector('[data-toggle="settings-modal"]'),
  (document.querySelector('.settings-icon').src =
    './assets/common/icons/cog.svg'),
  (document.querySelector('.close-icon').src = './assets/common/icons/closeIcon.svg'),

);

/* ---------------------------------------------------------------- */

// stats modal to display statistics

const statsModal = new Modal(
  'statsModal',
  statsModalBody,
  document.querySelector('[data-toggle="stats-modal"]'),
  (document.querySelector('.closed-icon').src = './assets/common/icons/closeIcon.svg'),

);

/* ---------------------------------------------------------------- */

// alert modal to ensure user means to close their current game

const alertsModal = new Modal(
  'alertsModal',
  alertsModalBody,
  document.querySelector('[data-toggle="alerts-modal"]'),
);

/* ---------------------------------------------------------------- */

// game change state which triggers various animation

function changeGameState(gamestate) {
  client.gameState = gamestate;
}

// assets have been downloaded and initialized when the game is in the ready state

function isReady() {

  // any state other than none is considered ready
  return client.gameState != client.GAMESTATE.NONE;
}

// return true if an account is signed in

function isAccountSignedIn() {
  if (playerData != undefined && playerData.userId != undefined && playerData.signedIn == true) {
    return true;
  }
  return false;
}

// return a userinfo message

function getUserInfoMessage(isSignedIn=true){

  let returnValue = {
    action: 'userinfo',
    gameReturnKey: undefined,
    receiptToken: '60f64e6cf2',
    signedIn: isSignedIn ? true : false,
    siteSlug: 'weartv',
    userId: isSignedIn ? '64d6bff79678272ff5835866' : undefined
  };
  return returnValue;
}

//return a start message

function getStartMessage(){
  let returnValue = {
    action: 'start',
  };
  return returnValue;
}

// return true if the window is an iframe of another window

function hasWindowParent(){
  return window != window.parent;
}

// only send the given message to a window parent if that parent is different from this window.

function sendMessageToWindowParent(message){

  if(hasWindowParent()){
    window.parent.postMessage(message, '*');
  }
}

// hide or show an element that is 'flex' when visible

function toggleFlexDisplayVisibility(element, isVisible){
  if(isVisible){
    element.style.display = 'flex';
  } else {
    element.style.display = 'none';
  }
}

/* ---------------------------------------------------------------- */

// send analytics data to parent

function sendGAData(title) {
  let analytics = { gameData: title };
  sendMessageToWindowParent(analytics);
}

/* ---------------------------------------------------------------- */

// set the timer and display minutes/seconds when applicable

function startGameTimer() {

  let minutesDisplay, secondsDisplay, hoursDisplay;
  timerInterval = setInterval(function () {

    if (settingsModal.isOpen || statsModal.isOpen) {
      if (/debug/.test(location.search)) {
        console.debug(
          'timerPaused'
        );
      }
    } else { // if modals are closed, resume time

      client.secondsPlayed++;

      client.minutes = Math.floor(client.secondsPlayed / 60);
      if (client.minutes < 10) {
        minutesDisplay = `0${client.minutes}`;
      } else {
        minutesDisplay = client.minutes;
      }

      client.seconds = client.secondsPlayed;
      secondsDisplay = client.seconds % 60;

      if (secondsDisplay < 10) {
        secondsDisplay = `0${secondsDisplay}`;
      }

      if (client.secondsPlayed > 3600) {

        minutesDisplay = client.minutes % 60; // have to have this in here once we reach over 59 minutes.

        if (minutesDisplay < 10) {
          minutesDisplay = `0${minutesDisplay}`;
        }

        client.hours = Math.floor(client.minutes / 60);

        if (client.hours < 10 && client.hours >= 0) {
          hoursDisplay = `0${client.hours}`;
        } else {
          hoursDisplay = client.hours;
        }

        TIMER.innerHTML = `${hoursDisplay}:${minutesDisplay}:${secondsDisplay}`;
      } else {
        TIMER.innerHTML = `00:${minutesDisplay}:${secondsDisplay}`;
      }
    }

  }, 1000);
}

/* ---------------------------------------------------------------- */

// Initializing the game - should run when page loads.

function initializeGame() {
  CARDS.initializeDeck(SOL_Images); // working;
  changeGameState(client.GAMESTATE.INITIALIZING);
}

/* ---------------------------------------------------------------- */

// Customize image paths based on stations calletters

async function localizeImages(station, city = false) {
  console.log('localizeImages(): station test', station);

  station.toUpperCase();
  if (!station || station.length < 4) { // default to XTRA if invalid station provided.
    client.station = 'XTRA';
    console.log('localizeImages(): Did not receive a valid station');
  } else { // if we have a valid city

    if (city) {
      city.toUpperCase();
      // update the page title with it's proper city name, if provided
      document.querySelector('.local-title').innerHTML = `SOLITAIRE ${city}`;
    }

    // update the logo in the top right to the proper station
    document.querySelector('.station-logo').src = `./assets/common/logos/solitaire-${station}-logo.png`;
    client.station = station;
    console.log('client.station after message received: ', client.station);
    await SOL_Images.downloadImages('common', client.station);// HACK should not initialize in two places
    await SOL_Images.downloadImages(client.cardChoice, client.station);// HACK should not initialize in two places
    await SOL_Images.initImages(client.cardChoice, client.station); // HACK should not initialize in two places
    initializeGame();

  }
}

/* ---------------------------------------------------------------- */

// reset time, points, moves, and add 1 gameCount

function navReset() {

  clearInterval(timerInterval);
  client.hours = 0;
  client.minutes = 0;
  client.seconds = 0;
  client.secondsPlayed = 0;
  client.deckFlip = 0;
  client.points = 0;
  client.moves = 0;
  SOL_Update.undoIconUpdate(SOL_Undo.undoStack.length, UNDO);
  SOL_Update.updateMovesAndPoints(client);
  TIMER.innerHTML = '00:00:00';
  POINTS.innerHTML = '0 Points';

}

/* ---------------------------------------------------------------- */

// runs at 60 FPS

function renderGame() {
  if (client.layout && client.placement) { // HACK
    SOL_Animation.update();
    SOL_Render.render();
  }
}

/* ---------------------------------------------------------------- */

// start the game (will probably have more complexity)

function startGame() {
  startGameTimer(); // set game time to 0:00 and count up
  SOL_Animation.flipTopCards();

  sendMessageToWindowParent({action: 'start'});
}

/* ---------------------------------------------------------------- */

// check score, check moves, check for win.

function updateLayout() {

  if (client.layout.width) {

    if (client.cardChoice != 'accessibility' && client.cardChoice != 'accessibility1') {  // check to see which mode to resize to. Keep deck the same (mobile / desktop)
      SOL_Canvas.setPlayerDevice(client);
      client.playerDevice == 'mobile' ? client.cardChoice = 'mobile' : client.cardChoice = 'desktop';
    } else if (client.cardChoice == 'accessibility' || client.cardChoice == 'accessibility1') {
      SOL_Canvas.setPlayerDevice(client);
    }
    // updates the UI settings modal based on deck choice of player.
    SOL_Options.deckChoice(client.cardChoice, client.playerDevice);
    // pulls the correct images for the deck choice
    CARDS.setImageType(SOL_Images, client.cardChoice);
    SOL_Canvas.resize(client, SOL_Render);

  }

}

/* ---------------------------------------------------------------- */

// saving SOL_Object to the server DataBase and to local storage

function solitaireSave(SOL_Object) {
  if (SOL_Object.uuid == '') {
    SOL_Object.uuid = UUID();
  }
}

/* ---------------------------------------------------------------- */

// load a game up and save to the server

function newGame() {
  document.querySelector('.navbar').style.display = 'flex';
  document.querySelector('.button-container-desktop').style.display = 'block';
  document.querySelector('#btn-alerts').style.display = 'block';

  toggleFlexDisplayVisibility(timerAndPointsDiv, SOL_Object.isTrial == false);

  if (client.playerDevice == 'mobile') {
    document.querySelector('.button-container-desktop').style.display = 'inline-block';
  }
  client.gameMode = SOL_Options.gameMode();
  if (client.gameMode == client.GAMEMODE.STANDARD) {
    client.wasteCardCount = 1;
    sendGAData('Standard');
  } else {
    sendGAData('Klondike');
  }

  // if (client.seed.length == 0) {
  CARDS.shuffle(client.seed);               // creating a seed array while we shuffle
  // } else {
  //   CARDS.playSeed(client.seed);         // using a seed to shuffle a specific deck
  // }

  CARDS.deal(client.placement);
  updateLayout();
  SOL_Undo.pushState(true);                     // every time we deal, we push the state to prime undoStack
  SOL_Update.undoIconUpdate(SOL_Undo.undoStack.length, UNDO);
  SOL_Animation.dealCards();
  SOL_Render.renderBackground(client.layout);
  solitaireSave(SOL_Object);

  // as soon as the game is initialized, we save the the game state in our DB

  changeGameState(client.GAMESTATE.PLAYING);
  clearInterval(timerInterval);
  client.winDelay = 0;
  client.secondsPlayed = 0;
  client.seconds = 0;
  client.minutes = 0;
  client.hours = 0;
  client.points = 0;
  client.moves = 0;
  client.cardsDealt = true;
  // SOL_Update.updateMovesAndPoints();
  startGame();
}

/* ---------------------------------------------------------------- */

// game win animation and gamestate updated

function gameWon() {
  sendGAData('Won');
  changeGameState(client.GAMESTATE.WON);

  clearInterval(timerInterval);
  SOL_Score.calculateWinningScore(client.points);
  SOL_Update.updateStats(client);
  SOL_Update.updateStatsModal();

  setTimeout(() => {
    changeGameState(client.GAMESTATE.COMPLETE); // important, sets amount of time the confetti goes!
  }, 2000);



  setTimeout(() => {
    statsModal.open(); // important, sets amount of time the stats modal is open!
  }, 3000);

  // setTimeout(() => { // important, sets the amount of time before a new game begins to initialize!
  //   navReset();
  //   initializeGame();
  //   newGame();
  //   // statsModal.close();
  // }, 5000);

}

/* ---------------------------------------------------------------- */

// results in game loss

function gameLost() {
  sendGAData('Lost');
  changeGameState(client.GAMESTATE.LOST);
  SOL_Update.updateMovesAndPoints(client);
  client.points = 0;
}

/* ---------------------------------------------------------------- */

// check score, check moves, check for win.

function checkPlayerStats() {
  // calculate the score and moves
  if (client.gameState == client.GAMESTATE.WON) {
    return;
  }
  // SOL_Update.updateMovesAndPoints();
  SOL_Score.checkForWin(); // check for win

  if (client.gameState == client.GAMESTATE.WON) {
    return gameWon();
  }
}

/* ---------------------------------------------------------------- */

// listen for click on desktop deck option. System defaults to desktop, updates based on playerDevice.

DESKTOPCARDS.addEventListener('click', (e) => {

  SOL_Options.deckChoice('desktop', client.playerDevice);
  client.cardChoice = 'desktop';
  CARDS.setImageType(SOL_Images, client.cardChoice);
  SOL_Canvas.resize(client, SOL_Render);
  SOL_Canvas.setCardPositions(client);

});

/* ---------------------------------------------------------------- */

// listen for click on mobile deck option

MOBILECARDS.addEventListener('click', (e) => {

  SOL_Options.deckChoice('mobile', client.playerDevice);
  client.cardChoice = 'mobile';
  CARDS.setImageType(SOL_Images, client.cardChoice);
  SOL_Canvas.resize(client, SOL_Render);
  SOL_Canvas.setCardPositions(client);

});

/* ---------------------------------------------------------------- */

// listen for click on on accessibility deck option

ACCESSIBILITYCARDS.addEventListener('click', (e) => {

  SOL_Options.deckChoice('accessibility', client.playerDevice);
  client.cardChoice = 'accessibility';
  CARDS.setImageType(SOL_Images, client.cardChoice);
  SOL_Canvas.resize(client, SOL_Render);
  SOL_Canvas.setCardPositions(client);

});

/* ---------------------------------------------------------------- */

// listen for click on accessibility1 deck option

ACCESSIBILITY1CARDS.addEventListener('click', (e) => {

  SOL_Options.deckChoice('accessibility1', client.playerDevice);
  client.cardChoice = 'accessibility1';
  CARDS.setImageType(SOL_Images, client.cardChoice);
  SOL_Canvas.resize(client, SOL_Render);
  SOL_Canvas.setCardPositions(client);

});


/* ---------------------------------------------------------------- */

// return window to the same state as it was before the game was activated
function deactivateGame() {
  playerData=undefined;
  navReset();
  window.activatedGameState.cleanupOnExit();
  delete window.activateGameState;
}

/* ---------------------------------------------------------------- */

// listen for click on game exit button, to close this iFrame in news site
for (let i = 0; i < EXITGAME.length; i++) {
  EXITGAME[i].addEventListener('click', (e) => {

    // update stats
    gameLost();

    if (/debug/.test(location.search)) {
      console.debug(
        'Exit button clicked'
      );
    }

    const { pointsCurrent} = JSON.parse(localStorage.getItem('stats'));
    var secondsPlayed = client.secondsPlayed;

    playerData = {
      userId: playerData.userId,
      complete: false,
      pointsCurrent: pointsCurrent,
      timePlayed: secondsPlayed
    };

    if (client.gameState == client.GAMESTATE.COMPLETE) {
      playerData.complete = true;
    }

    console.log('playerData: ', playerData);

    // send results message to parent if the game is complete, or else send an exit message

    let messageToParent;

    if (playerData.complete == true) {
      messageToParent = {
        action: 'results',
        complete: playerData.complete,
        score: playerData.pointsCurrent,
        timePlayed: playerData.timePlayed
      };
    } else {
      messageToParent = {
        action: 'exit',
        complete: playerData.complete
      };
    }
    sendMessageToWindowParent(messageToParent);
    deactivateGame();

    settingsModal.close();
    statsModal.close();
    alertsModal.close();
  });
}

/* ---------------------------------------------------------------- */

// reset stats on button click

RESETSTATS.addEventListener('click', (e) => {

  SOL_Score.createLocalScoreObject();
  navReset();
  initializeGame();
  newGame();
  setTimeout(() => {
    settingsModal.close();
  }, 500);

});


/* ---------------------------------------------------------------- */

// create a new game with three flip cards

THREECARDDEAL.addEventListener('click', (e) => {

  SOL_Options.gameMode();
  gameLost();
  changeGameState(client.GAMESTATE.INITIALIZING);
  navReset();
  initializeGame();
  newGame();
  setTimeout(() => {
    settingsModal.close();
  }, 500);

});

/* ---------------------------------------------------------------- */

// when the "New Game" button is pressed on the main page, initialize a new game.

NEW.addEventListener('click', () => {

  SOL_Update.newGameIconUpdate(NEW);
  sendGAData('New');
  gameLost();
  changeGameState(client.GAMESTATE.INITIALIZING);
  navReset();
  initializeGame();
  newGame();
});

/* ---------------------------------------------------------------- */

// undo button event listener

UNDO.addEventListener('click', () => {  //TODO: make the pointer event none
  if (SOL_Undo.undoStack.length < 2) {
    return;
  }                                  // no undos available
  if (/debug/.test(location.search)) {
    console.debug(
      'Undo button clicked'
    );
  }
  SOL_Undo.popState();
  SOL_Update.undoIconUpdate(SOL_Undo.undoStack.length, UNDO, true);           // true param indicates that the button was clicked
  SOL_Canvas.setCardPositions(client); // update card positions since we have movement.
  CARDS.updateFlags(client.placement); // important for mouse functions on the next player input
  checkPlayerStats(); // show updated score and moves now that move is complete.
  SOL_Update.updateStats(client);
  SOL_Update.updateStatsModal();
});

/* ---------------------------------------------------------------- */

// wait for player to hover over a card, returns hovered card.

function handleMousemove(mouseX, mouseY) {

  if (settingsModal.isOpen || statsModal.isOpen || !(client.cardsDealt)) {
    return;
  }

  if (window.innerWidth < window.innerHeight && client.layout.width > client.layout.height) {
    client.mouseX = mouseY;
    client.mouseY = mouseX;
  } else {
    client.mouseX = mouseX; // saving in the SOL_Object global.
    client.mouseY = mouseY;
  }

  const prevHoverCard = client.hoveredCard; // apply a temp state so we aren't spamming the console and our game engine
  if (client.draggingCard && window.innerWidth < window.innerHeight && client.layout.width > client.layout.height) {
    client.hoveredCard = CARDS.findHovered(mouseX, mouseY, client.deltaY, client.deltaX);
  } else {
    client.hoveredCard = CARDS.findHovered(mouseX, mouseY, client.deltaX, client.deltaY);
  }

  if (client.hoveredCard != prevHoverCard) {
    if (client.hoveredCard) {
      if (/debug/.test(location.search)) {
        console.debug(
          'Mousemove: ', client.hoveredCard.name
        );
      }
    }
  }
}

/* ---------------------------------------------------------------- */

// assign draggingCard, find it and if any cards are dragging along with it. A stock click will be rejected.

function handleMousedown() {
  if (client.animate) {
    return;
  }
  if (!client.hoveredCard || !client.hoveredCard.up) {
    return;
  }

  if (client.hoveredCard && !client.hoveredCard.isInStock) {
    // BUTTONCONTAINER.style.zIndex = 0; // z-index set to 0 when card is being dragged over the nav bar so the card position stays relevant with the client mouse coordinates
    client.draggingCard = client.hoveredCard; // if we have a hoveredcard that isn't in stock, the dragging Card becomes the hoveredCard
    if (client.draggingCard.isOnTableau) {   // check if we are trying to pick up more than one card.
      client.draggingCard.tableauArray = CARDS.findCard(client.draggingCard);
    } else {
      client.draggingCard.tableauArray = null;
    }
    if (client.draggingCard.tableauArray) {     // find the starting position of our dragging cards.
      client.draggingCard.startPosition = CARDS.findStartPosition(client.draggingCard.tableauArray);
    } else {
      client.draggingCard.startPosition = null;
    }

    client.deltaX = client.mouseX - client.draggingCard.xpos; // our delta is the distance between our mouseX (horizontal) and top left hand corner of the actual card.
    client.deltaY = client.mouseY - client.draggingCard.ypos; // our delta is the distance between our mouseY (vertical) and top left hand corner of the actual card.
  }

}

/* ---------------------------------------------------------------- */

// assign draggingCard, find it and if any cards are dragging along with it. A stock click will be rejected.

function handleMouseup() {
  // BUTTONCONTAINER.style.zIndex = 5; // z-index comes back to front when card is not being dragged
  if (client.animate) {
    return;
  }
  const draggingCard = client.draggingCard;
  client.draggingCard = null;

  if (!draggingCard || !client.hoveredCard) {
    if (draggingCard) {
      CARDS.resetTableauArray(draggingCard);
    }
    return;
  }// if we don't have a dragging or hovered card, early exit

  let validMove = CARDS.validateMove(draggingCard, client.hoveredCard); // also checks for empty tableau and empty foundation. Returns true/false

  if (client.hoveredCard.isOnFoundation) {
    validMove = validMove && CARDS.validateFrontCard(draggingCard);
  }

  if (validMove) {
    let move = CARDS.moveCards(draggingCard, client.hoveredCard); // move cards, including tableauArray
    client.moves++;
    SOL_Update.updateStats(client);
    SOL_Update.updateStatsModal();
    if (!move) {
      return;
    }
    SOL_Canvas.setCardPositions(client); // update card positions since we have movement.
    CARDS.updateFlags(client.placement); // important for our functions on the next player input
    checkPlayerStats(); // show updated score and moves now that move is complete.
    SOL_Undo.pushState();
    SOL_Update.undoIconUpdate(SOL_Undo.undoStack.length, UNDO);
  } else {
    if (draggingCard) {
      CARDS.resetTableauArray(draggingCard);
    }
  }
  // reset hoveredCard so if I don't move the mouse we start fresh.

  client.hoveredCard = CARDS.findHovered(client.mouseX, client.mouseY, client.deltaX, client.deltaY);
}

/* ---------------------------------------------------------------- */

// make sure click is on non-foundation card. Validate move and send it foundation with animation flag.

function handleDoubleClick() {
  if (client.animate) {
    return;
  }
  // we want to make sure the card is not on the foundation.
  if (client.hoveredCard && (client.hoveredCard.isInWaste || client.hoveredCard.isOnTableau)) {

    // now we want to make it the dragging card.
    const draggingCard = client.hoveredCard;

    client.hoveredCard = CARDS.validateFoundationMove(draggingCard);

    let validMove = true;

    if (client.hoveredCard !== null && client.hoveredCard.isOnFoundation) {
      validMove = CARDS.validateFrontCard(draggingCard);
    }

    if (client.hoveredCard !== null && validMove) {
      client.moves++;
      CARDS.moveCards(draggingCard, client.hoveredCard, true); // move cards, including tableauArray
      SOL_Canvas.setCardPositions(client); // update card positions since we have movement.
      CARDS.updateFlags(client.placement); // important for mouse functions on the next player input
      checkPlayerStats(); // show updated score and moves now that move is complete.
      if (client.gameState == client.GAMESTATE.WON) {
        return;
      }
      SOL_Update.updateStats(client);
      SOL_Update.updateStatsModal();
      SOL_Undo.pushState();
      SOL_Update.undoIconUpdate(SOL_Undo.undoStack.length, UNDO);
      localStorage.setItem('gameState', JSON.stringify(stats));
    }
  }
}


/* ---------------------------------------------------------------- */

// make sure click is on a stock card, then either turn it to waste or flip the waste deck over to stock

function handleClick() {

  if (!client.hoveredCard || client.animate) {
    return;
  }

  if (!client.placement.stock && !client.placement.waste) {
    return;
  }
  // if the hovered card is the empty stock, get that waste deck back over here.
  if (client.hoveredCard == client.emptyStockSlot && client.placement.waste.length) {
    CARDS.flipWasteDeckToStock(client.placement.stock, client.placement.waste);
    client.moves++;
    console.log('client: ', client.moves);
    client.deckFlip++;
    SOL_Canvas.setCardPositions(client);
    CARDS.updateFlags(client.placement);
    checkPlayerStats();
    SOL_Undo.pushState();
    SOL_Update.undoIconUpdate(SOL_Undo.undoStack.length, UNDO);
    client.hoveredCard = null;

  } else if (client.hoveredCard == client.placement.stock[client.placement.stock.length - 1]) {

    let flipCards = []; // temporary array only used for animation.
    CARDS.flipStockToWaste(client.placement.stock, client.placement.waste, flipCards); // pull card off stock and onto waste.
    SOL_Canvas.setCardPositions(client); // update card positions for move.
    CARDS.updateFlags(client.placement); // update the flags to complete the move.
    let timeDelay = -5; // no delay needed for first card.
    // flip animation for one or however many cards.
    flipCards.forEach(flipCard => {
      flipCard.up = false;
      flipCard.flip = true;
      timeDelay += 5;
      SOL_Animation.setCardAnimation(client.animSpeed, flipCard, flipCard.xpos, flipCard.ypos, client.layout.stockXpos, client.layout.stockYpos, timeDelay);
    });
    client.moves++;
    client.hoveredCard = null;
    SOL_Undo.pushState();
    SOL_Update.undoIconUpdate(SOL_Undo.undoStack.length, UNDO);
  }

  // attempting to reset dragging or hoveredCard so if I don't move the mouse we start fresh.
  client.hoveredCard = CARDS.findHovered(client.mouseX, client.mouseY, client.deltaX, client.deltaY);

}

/* ---------------------------------------------------------------- */

// handleTouchTap() -

function handleTouchTap(touch) {

  if (settingsModal.isOpen || statsModal.isOpen || client.animate) {
    return;
  }
  if (window.innerWidth < window.innerHeight && client.layout.width > client.layout.height) {
    handleMousemove(touch.mouseY, touch.mouseX); // need to get hoveredCard
    handleClick(touch.mouseY, touch.mouseX);
  } else {
    handleMousemove(touch.mouseX, touch.mouseY); // need to get hoveredCard
    handleClick(touch.mouseX, touch.mouseY);
  }
}

/* -------------------------------------------------------- -------- */

// handleDoubleTouch() -

function handleDoubleTouch(xpos, ypos) {

  if (settingsModal.isOpen || statsModal.isOpen || client.animate) {
    return;
  }
  if (window.innerWidth < window.innerHeight && client.layout.width > client.layout.height) {
    handleMousemove(ypos, xpos); // need to get hoveredCard
    handleClick(client.mouseY, client.mouseX);
  } else {
    handleMousemove(xpos, ypos); // need to get hoveredCard
    handleDoubleClick(client.mouseX, client.mouseY);
  }

}

/* ---------------------------------------------------------------- */

// handleTouchStart() -

function handleTouchStart(touch) {
  if (settingsModal.isOpen || statsModal.isOpen || client.animate) {
    return;
  }

  if (window.innerWidth < window.innerHeight && client.layout.width > client.layout.height) {
    handleMousemove(touch.mouseY, touch.mouseX);  // need to get hoveredCard
  } else {
    handleMousemove(touch.mouseX, touch.mouseY);  // need to get hoveredCard
  }

  if (SDG_Input.Touching()) {
    handleMousedown();
  }
}

/* ---------------------------------------------------------------- */

// handleTouchMove() -

function handleTouchMove(touch) {

  if (settingsModal.isOpen || statsModal.isOpen || client.animate) {
    return;
  }
  if (window.innerWidth < window.innerHeight && client.layout.width > client.layout.height) {
    handleMousemove(touch.mouseY, touch.mouseX);  // need to get hoveredCard
  } else {
    handleMousemove(touch.mouseX, touch.mouseY);
  }

}

/* ---------------------------------------------------------------- */

// mouseup and click called

function handleTouchEnd(touch) {

  if (settingsModal.isOpen || statsModal.isOpen || client.animate) {
    return;
  }
  handleMouseup();
  handleClick();
  client.hoveredCard = null;

}

/* ---------------------------------------------------------------- */

// Call resize when user changes viewport or rotates device

window.addEventListener('resize', () => {
  if (isReady()) {
    updateLayout();
  }
});

window.addEventListener('orientationchange', () => {
  if (isReady()) {
    updateLayout();
  }
});

/* ---------------------------------------------------------------- */

// wait for player to click on a card, returns clicked card

window.addEventListener('click', (event) => {

  if (settingsModal.isOpen || statsModal.isOpen || client.animate) {
    return;
  }
  event.preventDefault();  // might need these when we have a modal menu with an event listener.
  event.stopPropagation();
  handleClick();
});

/* ---------------------------------------------------------------- */


// wait for player to click on a card, returns clicked card

window.addEventListener('dblclick', (event) => {
  if (settingsModal.isOpen || statsModal.isOpen || client.animate) {
    return;
  }
  event.preventDefault();  // might need these when we have a modal menu with an event listener.
  event.stopPropagation();

  handleDoubleClick();
});

/* ---------------------------------------------------------------- */

// wait for the click down on a card, returns a draggable card if there is one

window.addEventListener('mousedown', (event) => {
  if (settingsModal.isOpen || statsModal.isOpen || client.animate) {
    return;
  }
  event.preventDefault();
  event.stopPropagation();
  handleMousedown();

});

/* ---------------------------------------------------------------- */

// wait for the mouse up event

window.addEventListener('mouseup', (event) => {

  if (settingsModal.isOpen || statsModal.isOpen) {
    return;
  }
  handleMouseup();

});

/* ---------------------------------------------------------------- */

// wait for player to hover over a card, returns hovered card.

window.addEventListener('mousemove', (event) => {

  if (settingsModal.isOpen || statsModal.isOpen || alertsModal.isOpen) {
    return;
  }

  const dpr = window.devicePixelRatio;
  let offsetX = event.offsetX;
  let offsetY = event.offsetY;
  offsetX = (Math.round(event.offsetX * dpr));
  offsetY = (Math.round(event.offsetY * dpr));

  handleMousemove(offsetX, offsetY);

});

/* ---------------------------------------------------------------- */

// ! -----------------------------------------IMPORTANT----------------------------------------- ! */


// initialize downloaded assets, notify game center the app is in the ready state

function GetReady(){

  SOL_Images.initImages(client.cardChoice, client.station);

  changeGameState(client.GAMESTATE.INITIALIZING);

  sendMessageToWindowParent({ action: 'ready' });
}

// called once when the page opens, initialize global game state, handle the asset downloads, then calls GetReady

function StartLoadingGame() {
  changeGameState(client.GAMESTATE.NONE);
  SOL_Canvas.setPlayerDevice(client);
  SOL_Canvas.setLayout(client);

  client.cardChoice = client.playerDevice; // default is desktop or mobile unless manually changed to accessibility by player.
  SOL_Options.deckChoice(client.cardChoice, client.playerDevice);

  return Promise.all([ SOL_Images.downloadImages('common', client.station),
    SOL_Images.downloadImages(client.cardChoice, client.station)]).then(GetReady);
}

// game initialization and the render loop. it will be triggered by a 'start' action post message.

function activateGame(isTrial = false) {

  window.activatedGameState = new SOL_ActivatedGameState();

  SOL_Object.isTrial=isTrial;

  initializeGame();
  let stats = JSON.parse(localStorage.getItem('stats'));

  // retrieving stats from local storage
  if (stats === null) {                                                    // saving scoring modal object to local storage
    stats = SOL_Score.createLocalScoreObject();
  }

  SOL_Update.updateStatsModal();
  // console.log(SOL_Object);

  newGame();

  SDG_Input.setTouchStartCallback(() => {
    handleTouchStart(SDG_Input.touch); // triggers mousemove (to get hovered card) and then mousedown
  });

  SDG_Input.setTouchMoveCallback(() => {
    handleTouchMove(SDG_Input.touch); // triggers mousemove and mousedown
  });

  SDG_Input.setTouchEndCallback(() => {
    handleTouchEnd(SDG_Input.touch); // triggers mouseup
  });

  SDG_Input.setTouchTapCallback(function (xpos, ypos, count) {

    if (count > 1) {
      handleDoubleTouch(xpos, ypos);
    } else {
      handleClick();
    }

    // handleDoubleTouch(xpos, ypos); // registers a double click or click
  }, 250, 500);

  window.activatedGameState.addIntervalByFPS(() => {
    renderGame();
    client.gameTick++;
  }, 60);
}

const sdGameMessagesIn = {
  // "player" message handler.
  userinfo: (data) => {
    playerData = data;
  },
  start: () => {
    areResourcesLoadedPromise.then(() => {

      let isTrial = isAccountSignedIn() == false;

      activateGame(isTrial);
    });
  },
  settings: () => {
    areResourcesLoadedPromise.then(()=>{                                               // "settings" message handler.
      // display game menu. // On close
      console.log('Settings action received');
      settingsModal.open();
      const res = {
        action: 'NOT MENU'
      };
      window.top.postMessage(res, '*');
    });
  },
  // "howto" message handler.
  howto: () => {
    areResourcesLoadedPromise.then(()=>{
      console.log('howto action received');
      settingsModal.open();
    });
  }
};

/* ---------------------------------------------------------------- */

// handle post messages

const postMessageHandler = (event) => {

  let body = event.data;

  console.log(body);

  if (body) {
    // detect wether or not the user is registered
    if (body.action == 'userinfo') {
      sdGameMessagesIn.userinfo(body);
    }

    if (body.action == 'settings') {
      // sends post message to parent iframe
      sdGameMessagesIn.settings();
    }

    if (body.action == 'howto') {
      sdGameMessagesIn.howto();
    }

    if (body.action == 'start') {
      sdGameMessagesIn.start();
    }

    // if not one of the top 10 stations, this will come through as undefined.
    // Sent to us by nucleus/awe when user clicks on Solitaire.
    if (body == 'callLetters') {
      localizeImages(body?.callLetters, body?.cityName);
    } else if (body.hasOwnProperty('callLetters')) {
      const stationCallLetters = ['KATU', 'KOMO', 'KSNV', 'KUTV', 'WBFF', 'WGME', 'WJAR', 'WJLA', 'WKRC', 'WOAI', 'XTRA'];
      if (!stationCallLetters.includes(body?.callLetters)) { // if the call letters aren't in this list, default to XTRA (generic)
        client.station = 'XTRA';
      } else if (stationCallLetters.includes(body?.callLetters)) { // if the call letters are in this list, use them!
        client.station = body?.callLetters;
      }

      console.log('body.hasOwnProperty: ', client.station, body?.cityName);
      localizeImages(client.station, body?.cityName);
    }
  }
};

/* ---------------------------------------------------------------- */

// listening for postMessages

const postMessageListener = () => {
  window.addEventListener('message', postMessageHandler, false);
  return () => window.removeEventListener('message', postMessageHandler);
};

/* ---------------------------------------------------------------- */

// start loading resources
areResourcesLoadedPromise = StartLoadingGame();

// listen for incoming post messages, the outer parent controls the initialization for this game
postMessageListener();

// Development toggle: set to true to run stand-alone by sending the same messages game center would send.
let isStandAlone = true;

// emulate running with game center by sending a userinfo and start message to the window.
if (isStandAlone) {
  areResourcesLoadedPromise.then(()=>{
    let userinfo = getUserInfoMessage(true);
    let start = getStartMessage();

    window.postMessage(userinfo);
    window.postMessage(start);
  });
}

// code archive: alternative isStandAlone implmentation.

// // set to false to run stand-alone
// let isWaitForGameCenter = false;

// if (isWaitForGameCenter) {
//   // listen for incoming post messages, the outer parent controls the initialization for this game
//   postMessageListener();

// } else {
//   sdGameMessagesIn.userinfo(getUserInfoMessage(false));
//   sdGameMessagesIn.start();
// }