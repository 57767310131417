/*
 *  modal.js
 *  Solitaire
 *
 *  Created by Kim Moran on Aug 18, 2022
 *  Copyright © 2022 Sinclair Digital. - All Rights Reserved
 *
 *  NavBar modal which contains game options, rules, and scoring methodology
 *
*/

export default class Modal {

  constructor(name, modal, target, SOL_Score, SOL_Update) {

    this.isOpen = false,
    this.name = name,
    this.modal = modal,
    this.target = target,
    this.closeModal = modal.querySelectorAll('[data-close]'),
    this.settingsButton = document.querySelector('[data-toggle="settings-modal"]');
    this.statsButton = document.querySelector('[data-toggle="stats-modal"]');
    this.alertsButton = document.querySelector('[data-toggle="alerts-modal"]');
    this.settingsIcon = document.querySelector('.settings-icon');
    this.statsIcon = document.querySelector('.stats-icon');
    this.alertsIcon = document.querySelector('.alerts-icon');

    /* ---------------------------------------------------------------- */

    this.modal.addEventListener('click', (e) => {

      // if you click anywhere in the area outside the modal, close it and reset images to white.
      if (e.target == this.modal) {
        this.isOpen = false;
        return this.close();
      }

    }),

    this.settingsButton.addEventListener('click', (e) => {
      if (this.name == 'statsModal' || this.name == 'alertsModal') {
        this.isOpen = false;
        return this.close();
      } else {
        if (this.isOpen) {
          this.isOpen = false;
          return this.close();
        } else {
          this.isOpen = true;
          return this.open();
        }
      }
    }),

    this.statsButton.addEventListener('click', (e) => {
      if (this.name == 'settingsModal' || this.name == 'alertsModal') {
        this.isOpen = false;
        return this.close();
      } else {
        if (this.isOpen) {
          this.isOpen = false;
          return this.close();
        } else {
          this.isOpen = true;
          return this.open();
        }
      }

    });

    this.alertsButton.addEventListener('click', (e) => {
      if (this.name == 'settingsModal' || this.name == 'statsModal') {
        this.isOpen = false;
        return this.close();
      } else {
        if (this.isOpen) {
          this.isOpen = false;
          return this.close();
        } else {
          this.isOpen = true;
          return this.open();
        }
      }

    });

    /* ---------------------------------------------------------------- */

    // if user presses the close button or the x in the top left hand corner, close it!

    this.closeModal.forEach((item) => {
      item.addEventListener('click', (e) => {
        this.isOpen = false;
        return this.close();
      });
    }),

    /* ---------------------------------------------------------------- */

    // modal open functionality

    this.open = function () {
      this.modal.classList.add('show-modal');
      this.isOpen = true;

      if (this.name == 'alertsModal') {
        this.statsIcon.src = './assets/common/icons/trophy.svg';
        this.settingsIcon.src = './assets/common/icons/cog.svg';
        this.alertsIcon.src = './assets/common/icons/close-lit.svg';
      }
      if (this.name == 'statsModal') {
        this.statsIcon.src = './assets/common/icons/trophy-lit.svg';
      }
      if (this.name == 'settingsModal') {
        this.settingsIcon.src = './assets/common/icons/cog-lit.svg';
      }
      setTimeout(() => {
        this.animateIn();
      }, 20);
    },

    /* ---------------------------------------------------------------- */

    // modal close functionality

    this.close = function () {
      this.animateOut();
      this.isOpen = false;

      // open and closed are also called directly from other files. Have to add in the new image source here since we can't call event listeners direct.
      if (this.name == 'settingsModal') {
        this.settingsIcon.src = './assets/common/icons/cog.svg';
      }
      if (this.name == 'statsModal') {
        this.statsIcon.src = './assets/common/icons/trophy.svg';
      }
      if (this.name == 'alertsModal') {
        this.alertsIcon.src = './assets/common/icons/close.svg';
      }
      setTimeout(() => {
        this.modal.classList.remove('show-modal');
      }, 250);
    },

    /* ---------------------------------------------------------------- */

    // not using these yet, but I'd like it to come in from the top of the page hypothetically

    this.animateIn = function () {
      this.modal.classList.add('animate-modal');
    },

    this.animateOut = function () {
      this.modal.classList.remove('animate-modal');
    };

    /* ---------------------------------------------------------------- */

  }

}
