/*
 *  SDG_Input.js
 *  Solitaire
 *
 *  Created by Kieran Hannagan & Kim Moran on Sept 30, 2022
 *  Copyright © 2022 Sinclair Digital. - All Rights Reserved
 *
 *  This is the main source file for handling player input.
 */
/* ---------------------------------------------------------------- */

export function SDG_Input(canvas) {

  let mouseX, mouseY;
  let touchtap_callback = null;
  let touchtaptime;

  let touchstart_callback = null;
  let touchmove_callback = null;
  let touchend_callback = null;
  let touchcancel_callback = null;
  let touchtime_callback = null;
  let doubletouch_callback = null;

  let touching = 0; // represents the number of fingers touching the screen

  let touchtime; // time to qualify for a single tap
  let touchtapdoubletime; // second tap must be less than this time
  let lastTouchTime; // last tap reported to client
  let doubleTime = 500;


  const touch = {};
  this.touch = touch;
  touch.mouseX = 0;  // This is a copy of where the finger touched last
  touch.mouseY = 0;
  touch.count = 0;
  let offset;

  /* ---------------------------------------------------------------- */

  // SetTouchTapCallback() - Sets the callback for a tap event

  this.setTouchTapCallback = function (callback, time, doubleTime=500) {
    touchtaptime = time;
    touchtapdoubletime = doubleTime;
    touchtap_callback = callback;

  };
  /* ---------------------------------------------------------------- */

  // TouchTap() - Called when a touch tap event is detected within "touchtaptime".

  function TouchTap(xpos, ypos) {
    if (touchtap_callback) {
      touchtap_callback(xpos, ypos, touch.count);
    }
  }
  /* ---------------------------------------------------------------- */

  // setTouchStartCallback() - Sets the Callback for when the screen is touched

  this.setTouchStartCallback = function (callback) {
    touchstart_callback = callback;
  };
  /* ---------------------------------------------------------------- */

  var touches;

  // TouchStart() - Callback for when the screen is touched

  function TouchStart(event) {
    // event.preventDefault();
    offset = event.target.getBoundingClientRect();
    const dpr = window.devicePixelRatio;

    touches = event.touches;

    mouseX = Math.floor(touches[0].clientX * dpr) - offset.left;
    mouseY = Math.floor(touches[0].clientY * dpr) - offset.top;

    touch.mouseX = mouseX;
    touch.mouseY = mouseY;

    touchtime = Date.now();		// Capture the time at start of touch event
    touching = 1;					// Begin touching event

    if (touchstart_callback) {
      touchstart_callback(mouseX, mouseY);
    }

  }
  /* ---------------------------------------------------------------- */

  // setTouchMoveCallback() - Sets the Callback for when a finger or stylus moves while contacting the screen

  this.setTouchMoveCallback = function (callback) {
    touchmove_callback = callback;
  };
  /* ---------------------------------------------------------------- */

  // TouchMove() - Callback for when a finger or stylus moves while contacting the screen

  function TouchMove(event) {
    // event.preventDefault();

    touches = event.touches;

    const dpr = window.devicePixelRatio;

    offset = event.target.getBoundingClientRect();

    mouseX = Math.floor(touches[0].clientX * dpr) - offset.left;
    mouseY = Math.floor(touches[0].clientY * dpr) - offset.top;

    touch.mouseX = mouseX;
    touch.mouseY = mouseY;

    if (touching)	{			// Moving the touch point cancels touching event
      touching = 0;
    }				// Finger must be still for this flag to stay set

    if (touchmove_callback) {
      touchmove_callback(event.touches, mouseX, mouseY);
    }
  }
  /* ---------------------------------------------------------------- */

  // setTouchEndCallback() - Sets the Callback for when a touch motion ends

  this.setTouchEndCallback = function (callback) {
    touchend_callback = callback;
  };
  /* ---------------------------------------------------------------- */

  // TouchEnd() - Callback for when a touch motion ends

  function TouchEnd(event) {
    // event.preventDefault();
    touches = event.touches;
    touching = 0;
    const timeNow = Date.now();
    // Releasing finger always cancels touch event

    //		mouseX=Math.floor(touches[0].clientX);
    //		mouseY=Math.floow(touches[0].clientY);

    if (touchend_callback) {
      touchend_callback(touch.mouseX, touch.mouseY);
    }

    if (timeNow < touchtime + touchtaptime){	// Was finger release in less than touchtaptime?
      if (timeNow - lastTouchTime < doubleTime){ // check if the second touch made it in time.
        touch.count++;
      } else{
        touch.count = 1;
      }

      lastTouchTime = timeNow;
      TouchTap(mouseX, mouseY, touch.count);
      // DoubleTouch(mouseX, mouseY);
    }
  }

  /* ---------------------------------------------------------------- */

  // // setTouchStartCallback() - Sets the Callback for when the screen is touched

  this.setDoubleTouchCallback = function (callback) {
    doubletouch_callback = callback;
  };

  /* ---------------------------------------------------------------- */

  function DoubleTouch(event) {

    touch.mouseX = mouseX;
    touch.mouseY = mouseY;
    if (doubletouch_callback) {
      doubletouch_callback(touch.mouseX, touch.mouseY);
    }

  }

  /* ---------------------------------------------------------------- */

  // setTouchCancelCallback() - Sets the Callback for when a touch event is cancelled

  this.setTouchCancelCallback = function (callback) {
    touchcancel_callback = callback;
  };
  /* ---------------------------------------------------------------- */

  // TouchCancel() - Callback for when a touch event is cancelled

  function TouchCancel(event) {
    touches = event.touches;

    if (touchcancel_callback) {
      touchcancel_callback(event);
    }

    for (var i = 0; i < touches.length; i++) {
      console.log('Touch Cancel -- x:' + touches[i].clientX + ' Y:' + touches[i].clientY);
    }
  }
  /* ---------------------------------------------------------------- */

  // TouchTime() - Sets the Callback for when a touch event reaches a certain time
  // NOTE: Not really used.  Don't really love this anyhow.

  this.setTouchTimeCallback = function (callback) {
    touchtime_callback = callback;
  };
  /* ---------------------------------------------------------------- */

  function TouchTime() {
    if (touching) {
      var time = Date.now();
      if (time > touchtime + 500) {
        touchtime = time;				// Reset touchtime

        if (touchtime_callback) {
          touchtime_callback(mouseX, mouseY, touching);
        }

        touching++;
      }
    }
  }

  /* ---------------------------------------------------------------- */

  this.Touching = function () {
    return touching;
  };

  /* ---------------------------------------------------------------- */

  // window.addEventListener("dblclick", ClickStart);
  canvas.addEventListener('touchstart', function HasTouch(){	// If user touches, then we have touch!
    window.removeEventListener('touchstart', HasTouch);
    var intID = setInterval(TouchTime, 100);	// Every 1/10th second
  }, { passive: true });
  canvas.addEventListener('touchstart', TouchStart, { passive: true });

  canvas.addEventListener('touchmove', TouchMove);
  canvas.addEventListener('touchend', TouchEnd);
  canvas.addEventListener('touchcancel', TouchCancel);

}