/*
 *  SOL_Server.js
 *  Solitaire
 *
 *  Created by Kieran Hannagan on Sept 7, 2022
 *  Copyright © 2022 Sinclair Digital. - All Rights Reserved
 *
 *  Client side server functions
 */

// NOTES

// The SOL_OBJECT defines the game and will be get/put in S3 bucket with any gameInit, newGame, restart, screenResize, userInteraction.

/* ---------------------------------------------------------------- */

// SOL_Server handles all the communication with the server.

export function SOL_Server() {

  this.saveGame = async function (solObject) {                          // saves the game state to the server and to local storage

    // localStorage.setItem(solObject.uuid, JSON.stringify(solObject));  // saving to local storage with a key value pair, the uuid is the key

    if (navigator.onLine) {                                             // checking to see if we have an internet connection, if so it will save to the server
      await fetch('/s3/upload', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(solObject),
      }).then(response => response.json())
        .then(data => {
          // console.log(data);
          return solObject;
        }).catch(err => {
          console.log(err);
        });
    }
  },

  /* ---------------------------------------------------------------- */

  // TODO: create a function to play a seed
  // (this.playSeed = async function (seed) {
  // }),

  /* ---------------------------------------------------------------- */

  // retrieve game data that was previously saved

  this.getDeck = async function () {
    const response = await fetch('/api/game-data/', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const deck = await response;

    if (response.ok) {
      // console.log(deck, 'Success');
      return await response;
    } else {
      const errorObj = await response;
      console.log(errorObj.message);
    }
  };

}