var myCanvas = document.createElement('wincanvas');
// myCanvas.height = (window.innerHeight - 80);
document.body.appendChild(myCanvas);
import confetti from 'canvas-confetti';

export function SOL_Particles(client) {


  this.confettiCall = async function () {

    var myConfetti = confetti.create(myCanvas, {
      // resize: true,
      useWorker: true,
      resize: false,
    });
    if (client.gameState == client.GAMESTATE.WON) {

      // launch a few confetti from the left edge
      confetti({
        particleCount: 7,
        angle: 50,
        spread: 70,
        origin: { x: 0 },
        colors: ['#FFFFFF', '#F6F0B2', '#00749D', '#EAC225', '#054863', '0F181E']
      });
      // and launch a few from the right edge
      confetti({
        particleCount: 7,
        angle: 130,
        spread: 70,
        origin: { x: 1 },
        colors: ['#FFFFFF', '#F6F0B2', '#00749D', '#EAC225', '#054863', '0F181E']

      });
      confetti({
        particleCount: 7,
        angle: 90,
        spread: 70,
        origin: { x: .5 },
        colors: ['#FFFFFF', '#F6F0B2', '#00749D', '#EAC225', '#054863', '0F181E']

      });

      await myConfetti();
      myConfetti.reset();

    } else {

      myConfetti.reset();
    }
  };

}



