/*
 *  SOL_Render.js
 *  Solitaire
 *
 *  Created by Kim Moran on Aug 22, 2022
 *  Copyright © 2022 Sinclair Digital. - All Rights Reserved
 *
 *  Take in layout and card data, call SDG_Draw lib based on params
 */
/* ---------------------------------------------------------------- */

// imports

import * as sdg_draw from '../../libs/SDG_Draw';
import * as particles from './SOL_Particles';

/* ---------------------------------------------------------------- */

const SDG_Draw = new sdg_draw.SDG_Draw();


export function SOL_Render(SOL_Object, SOL_Images, SOL_Canvas, SOL_Animation) {


  const { client } = SOL_Object;

  // declare static images - foundation, card background

  const { ctx, bctx, animctx, loadctx, loadCanvas } = SOL_Canvas;

  /* ---------------------------------------------------------------- */

  // renderCards() - this is called every frame and draws every card in the game

  this.renderCards = function () {

    SDG_Draw.clear(ctx, client.layout.width, client.layout.height);
    SDG_Draw.clear(animctx, client.layout.width, client.layout.height);

    let { foundation, stock, waste, tableau } = client.placement; // clear current cards so we don't have odd behavior

    this.renderEmptySlots(client);
    this.renderStock(client.layout, stock);
    this.renderWaste(client.layout, waste);
    this.renderShadowCard(client.layout);

    this.renderTab(client.layout, tableau.tab1);
    this.renderTab(client.layout, tableau.tab2);
    this.renderTab(client.layout, tableau.tab3);
    this.renderTab(client.layout, tableau.tab4);
    this.renderTab(client.layout, tableau.tab5);
    this.renderTab(client.layout, tableau.tab6);
    this.renderTab(client.layout, tableau.tab7);

    this.renderFnd(client.layout, foundation.fnd1);
    this.renderFnd(client.layout, foundation.fnd2);
    this.renderFnd(client.layout, foundation.fnd3);
    this.renderFnd(client.layout, foundation.fnd4);

    this.renderDraggingCard(client.layout, tableau);

    return;

  },

  /* ---------------------------------------------------------------- */

  // renders

  this.render = function () {
    this.renderCards();
    if (client.gameState == client.GAMESTATE.WON) { // TODO: this logic belongs in main
      this.renderGameWinExplosion();
    }
  },
  /* ---------------------------------------------------------------- */

  // paints the background to the screen on the back canvas, not the primary canvas.

  this.renderBackground = function (layout) {
    // recreates a background scaling to 'fill', don't lose our h/w scale yet fill the screen with image
    // render this image dynamically once we are on more than one 'test' site or give player background image options.
    const backgroundImg = SOL_Images.getImage(`common/backgrounds/solitaire-${client.station}-background.png`);
    const imageWidth = backgroundImg.width;
    const imageHeight = backgroundImg.height;
    const canvasWidth = layout.width;
    const canvasHeight = layout.height;
    const orientation = Math.max(canvasWidth / imageWidth, canvasHeight / imageHeight);

    SDG_Draw.clear(bctx, layout.width, layout.height);
    SDG_Draw.transform(bctx, backgroundImg, orientation, 0, 0, orientation, (canvasWidth - orientation * imageWidth) / 2, (canvasHeight - orientation * imageHeight) / 2);

    return;
  },

  /* ---------------------------------------------------------------- */

  // render the shadow card under the hoveredCard.

  this.renderLoadScreen = function (layout, percentage, load = true) {
    SDG_Draw.clear(loadctx, loadCanvas.width, loadCanvas.height); // clear canvas first
    if (!load) {
      return;
    } // early exit if load done
    const loadingBackground = SOL_Images.getImage(`common/loading/solitaire-splashscreen-background.png`);
    let logo = SOL_Images.getImage(`common/logos/solitaire-${client.station}-logo.png`);
    if (!logo) {
      logo = SOL_Images.getImage('common/logos/solitaire-XTRA-logo.png'); // safeguard if postMessage for station localization doesn't reach our function in time.
    }

    // 1105px/444px high
    function drawBackground(image) {

      let wrh = image.width / image.height;
      let newWidth = loadCanvas.width;
      let newHeight = newWidth / wrh;
      if (newHeight > loadCanvas.height) {
        newHeight = loadCanvas.height;
        newWidth = newHeight * wrh;
      }

      let scale = Math.max(loadCanvas.width / image.width, loadCanvas.height / image.height);
      let x = (loadCanvas.width / 2) - (image.width / 2) * scale;
      let y = (loadCanvas.height / 2) - (image.height / 2) * scale;

      SDG_Draw.draw(loadctx, loadingBackground, x, y, image.width * scale, image.height * scale);
    }

    function drawLogo(image) {

      let wrh = image.width / image.height;

      let maxWidth = (loadCanvas.width * .5);
      let newWidth = Math.min(maxWidth, 1105);

      if (client.playerDevice == 'mobile') {
        newWidth = loadCanvas.width;
      }

      let newHeight = newWidth / wrh;
      if (newHeight > loadCanvas.height) {
        newHeight = loadCanvas.height;
        newWidth = newHeight * wrh;
      }
      var xOffset = newWidth < loadCanvas.width ? ((loadCanvas.width - newWidth) / 2) : 0;
      var yOffset = newHeight < loadCanvas.height ? ((loadCanvas.height - newHeight) / 2) : 0;

      SDG_Draw.draw(loadctx, logo, xOffset, yOffset, newWidth, newHeight);
    }

    // loading bar colors.
    const baseColor = '#444444';
    const progressColor = '#FFFFFF';

    const dWidth = Math.min(loadCanvas.width / 2, 300 * window.devicePixelRatio); // bar should take up 50% of screen width but not more than 300 px wide
    const dHeight = dWidth / 15; // rectangle should be proportionate.

    const dx = loadCanvas.width / 2 - dWidth / 2; // get the middle of the canvas less half of our item width / height
    const dy = loadCanvas.height - dHeight - dHeight; // I actually want this on the bottom portion of the screen so the logo is still visible

    SDG_Draw.drawShape(loadctx, 'rgba(0,0,0)', 0, 0, loadCanvas.width, loadCanvas.height);

    drawBackground(loadingBackground);
    drawLogo(logo);

    SDG_Draw.drawLoadingBar(loadctx, percentage, baseColor, progressColor, dx, dy, dWidth, dHeight);

  },

  /* ---------------------------------------------------------------- */

  // render the shadow card under the hoveredCard.

  this.renderShadowCard = function (layout) {
    const foundationImg = SOL_Images.foundationImg;
    const emptyImg = SOL_Images.emptyImg;

    const { draggingCard, hoveredCard, mouseX, mouseY, deltaX, deltaY } = client;

    if (!hoveredCard) {
      return;
    }
    if (hoveredCard == draggingCard) {
      return;
    }

    if ((hoveredCard.isOnTableau && hoveredCard.up) || hoveredCard.isOnFoundation) {
      SDG_Draw.drawShadowCard(
        animctx, emptyImg, hoveredCard.xpos, hoveredCard.ypos, layout.cardWidth, layout.cardHeight, '#fff', 8, 1, 1);
    }
    if (hoveredCard.name == 'emptyFound1' || hoveredCard.name == 'emptyFound2' || hoveredCard.name == 'emptyFound3' || hoveredCard.name == 'emptyFound4') {
      SDG_Draw.drawShadowCard(
        animctx, foundationImg, hoveredCard.xpos, hoveredCard.ypos, layout.cardWidth, layout.cardHeight, '#fff', 7, 1, 1);
    }

  },

  /* ---------------------------------------------------------------- */

  // paint the empty arrays with the foundation image.

  this.renderEmptySlots = function (client) {
    const foundationImg = SOL_Images.foundationImg;
    const tableauImg = SOL_Images.tableauImg;
    const emptyImg = SOL_Images.emptyImg;

    SDG_Draw.draw( // draw the stock foundation image
      ctx, emptyImg, client.emptyStockSlot.xpos, client.emptyStockSlot.ypos, client.layout.cardWidth, client.layout.cardHeight
    );

    SDG_Draw.draw( // draw the waste foundation image
      ctx, emptyImg, client.emptyWasteSlot.xpos, client.emptyWasteSlot.ypos, client.layout.cardWidth, client.layout.cardHeight
    );

    for (let i = 0; i < 4; i++) { // iterate through foundation to fill empty array with foundation image

      SDG_Draw.draw(
        ctx, foundationImg, client.emptyFoundationSlots[i].xpos, client.emptyFoundationSlots[i].ypos, client.layout.cardWidth, client.layout.cardHeight
      );
    }

    for (let i = 0; i < 7; i++) { // iterate through tableau to fill empty array with foundation image

      SDG_Draw.draw(
        ctx, tableauImg, client.emptyTableauSlots[i].xpos, client.emptyTableauSlots[i].ypos, client.layout.cardWidth, client.layout.cardHeight
      );
    }
  },

  /* ---------------------------------------------------------------- */

  // paint the stock with card background.

  this.renderStock = function (layout, stock) {
    // assign xpos in client for this card's event detection. Only need the first one in the array.
    const cardBackImg = SOL_Images.cardBackImg;

    if (stock.length) {

      let length = Math.min(stock.length / 2, 5);
      ctx.save();

      for (let i = 0; i < length; i++) {

        SDG_Draw.drawShadow(
          ctx,
          cardBackImg,
          layout.stockXpos,
          layout.stockYpos - (i * 1.75),
          layout.cardWidth,
          layout.cardHeight,
          'rgb(0,0,0,.25)',
          10,
          0,
          5
        );
      }
      ctx.restore();
    }
    return stock;
  },

  /* ---------------------------------------------------------------- */

  // paint one waste card to simplify the renderWaste() functionality

  this.renderWasteCard = function (card, offset = 0) {
    const image = card.up ? card.image : SOL_Images.cardBackImg;

    let xpos = card.animate ? card.animX : card.xpos + offset;
    let ypos = card.animate ? card.animY : card.ypos;

    if (client.playerDevice == 'mobile') {
      xpos = card.animate ? card.animX : card.xpos;
      ypos = card.animate ? card.animY : card.ypos + offset;
    }

    // xpos += offset * 17;
    const scaleX = card.flip ? card.scaleX : 1;
    const scaleY = card.flip ? card.scaleY : 1;

    // adjust xpos to center of card if flipping
    xpos = card.flip ? card.animX + client.layout.cardWidth / 2 * Math.abs(scaleX - 1) : xpos;

    ctx.save();
    ctx.scale(scaleX, scaleY);
    ctx.restore();

    SDG_Draw.draw(
      ctx,
      image,
      xpos,
      ypos,
      client.layout.cardWidth * scaleX,
      client.layout.cardHeight * scaleY,
    );

  },
  /* ---------------------------------------------------------------- */

  // paint the waste with image source or card back depending on flag.

  this.renderWaste = function (layout, waste) {
    const gameMode = client.gameMode;
    const draggingCardNum = !client.draggingCard ? 52 : client.draggingCard.cardNum;

    if (gameMode == client.GAMEMODE.STANDARD) {
      if (!waste.length) {
        return;
      } else {
        if (waste.length > 1) {
          this.renderWasteCard(waste[waste.length - 2]); // render the card anyway even if it's covered.
        }
      }

      let card = waste[waste.length - 1];

      if (draggingCardNum == card.cardNum && waste.length == 1) {
        return;
      }

      if (draggingCardNum == card.cardNum && !card.animate) {
        card = waste[waste.length - 2];
      }

      this.renderWasteCard(card);

      return waste;
    } else { // if not standard, it's Klondike. Only two modes as of 10.28.2022 kamoran.

      // two code paths, appears wasteful. Will see commonality and merge it together after it works. Second phase, the code optimization/merge phase.

      if (!waste.length) {
        return;
      } // still true

      let offset;
      if (client.playerDevice == 'mobile') {
        offset = 30 * window.devicePixelRatio;
      } else { //any desktop
        offset = layout.cardWidth * .15;

        if (client.cardChoice == 'accessibility') { // want this to override the mobile option.
          offset = layout.cardWidth * .3;
        }
        if (client.cardChoice === 'accessibility1') { // want this to override the mobile option
          offset = layout.cardWidth * .4;
        }
      }

      /* ---------------------------------------------------------------- */

      // WASTE CARD COUNT = 3 SCENARIOS

      let topCard = waste[waste.length - 1];
      if (client.wasteCardCount == 3) {

        // If waste card count is 3 and we have at least 6 cards, render the most recent three so the black background doesn't show on flip.
        if (waste.length >= 6) {
          const sixthCard = waste[waste.length - 6];
          sixthCard.up;
          this.renderWasteCard(sixthCard);

          const fifthCard = waste[waste.length - 5];
          fifthCard.up;
          this.renderWasteCard(fifthCard, offset);

          // if top card is dragging, only show the two underneath or it looks weird
          if (draggingCardNum != topCard.cardNum) {
            const fourthCard = waste[waste.length - 4];
            fourthCard.up;
            this.renderWasteCard(fourthCard, offset * 2);
          }
        }

        // If waste card count is 3 but we only have 5 cards, render the two underneath so the black background doesn't show on flip
        if (waste.length == 5) {

          const fifthCard = waste[waste.length - 5];
          fifthCard.up;
          this.renderWasteCard(fifthCard);


          const fourthCard = waste[waste.length - 4];
          fourthCard.up;
          this.renderWasteCard(fourthCard, offset);
        }


        // render the waste card underneath. Relevant during game beginning or once we get low in cards
        if (waste.length == 4) {

          const fourthCard = waste[waste.length - 4];
          fourthCard.up;
          this.renderWasteCard(fourthCard);
        }

        //continue rendering the normal top three cards regardless if waste length is 4 or more

        const thirdCard = waste[waste.length - 3];
        thirdCard.up;
        this.renderWasteCard(thirdCard);


        const secondCard = waste[waste.length - 2];
        secondCard.up;
        this.renderWasteCard(secondCard); // 2nd card from top, rendered second.

        if (draggingCardNum == topCard.cardNum && waste.length == 1) {
          return;
        }

        if (draggingCardNum == topCard.cardNum && !topCard.animate) { // if we are dragging that top waste card, just render the two or one underneath as they are
          topCard = waste[waste.length - 2];
          this.renderWasteCard(topCard);
        } else {
          this.renderWasteCard(topCard);
        }

      }

      /* ---------------------------------------------------------------- */

      // WASTE CARD COUNT = 2 CARD SCENARIOS

      if (client.wasteCardCount == 2) {

        if (waste.length == 4) {

          const fourthCard = waste[waste.length - 4];
          fourthCard.up;
          this.renderWasteCard(fourthCard);


          if (draggingCardNum != topCard.cardNum) {
            const thirdCard = waste[waste.length - 3];
            thirdCard.up;
            this.renderWasteCard(thirdCard, offset);
          }
        }

        const secondCard = waste[waste.length - 2];
        secondCard.up;
        this.renderWasteCard(secondCard);

        // let topCard = waste[waste.length - 1];

        if (draggingCardNum == topCard.cardNum && waste.length == 1) {
          return;
        }

        if (draggingCardNum == topCard.cardNum && !topCard.animate) { // if we are dragging that top waste card, just render the two or one underneath as they are
          topCard = waste[waste.length - 2];
          this.renderWasteCard(topCard);
        } else {
          this.renderWasteCard(topCard);
        }

      }

      /* ---------------------------------------------------------------- */

      // WASTE CARD COUNT = 1 CARD SCENARIOS

      if (client.wasteCardCount == 1) {
        topCard = waste[waste.length - 1];

        if (draggingCardNum == topCard.cardNum && waste.length == 1) {
          return;
        }

        if (draggingCardNum == topCard.cardNum && !topCard.animate) { // if we are dragging that top waste card, just render the two or one underneath as they are
          topCard = waste[waste.length - 2];
          this.renderWasteCard(topCard);
        } else {
          this.renderWasteCard(topCard);
        }
      }

      return waste;
    }

  },

  /* ---------------------------------------------------------------- */

  // paint the tableau with image source or card back depending on flag.

  this.renderTab = function (layout, tableau) {
    let { cardWidth, cardHeight } = layout;
    const draggingCardNum = !client.draggingCard ? 52 : client.draggingCard.cardNum;

    tableau.forEach(function (card, i) { // if we get here, we know there are cards in the tableau
      let image = card.up ? card.image : SOL_Images.cardBackImg;

      let xpos = card.animate ? card.animX : card.xpos;
      let ypos = card.animate ? card.animY : card.ypos;

      const scaleX = card.flip ? card.scaleX : 1;
      const scaleY = card.flip ? card.scaleY : 1;

      // adjust xpos to center of card if flipping
      xpos = card.flip ? xpos + layout.cardWidth / 2 * Math.abs(scaleX - 1) : xpos;

      if (draggingCardNum != card.cardNum && card.child) {

        ctx.save();
        ctx.scale(scaleX, scaleY);
        ctx.restore();

        SDG_Draw.draw(
          ctx,
          image,
          xpos,
          ypos,
          cardWidth * scaleX,
          cardHeight * scaleY,
        );
      }
    });
  },

  /* ---------------------------------------------------------------- */

  // paints the incoming foundation

  this.renderFnd = function (layout, fnd) {
    let { cardWidth, cardHeight } = layout;
    const draggingCardNum = !client.draggingCard ? 52 : client.draggingCard.cardNum;

    fnd.forEach(function (card, i) { // if we get here, we know there are cards in the tableau

      const xpos = card.animate ? card.animX : card.xpos;
      const ypos = card.animate ? card.animY : card.ypos;

      if (draggingCardNum != card.cardNum) {

        SDG_Draw.draw(ctx, card.image, xpos, ypos, cardWidth, cardHeight);

      }

    });

    return fnd;
  },

  /* ---------------------------------------------------------------- */

  // paint the dragging card and children to the screen.

  this.renderDraggingCard = function (layout) {
    const { draggingCard, mouseX, mouseY, deltaX, deltaY } = client;

    if (!draggingCard) {
      return;
    }
    if (!draggingCard.up) {
      return;
    }

    // should draw the main dragging card
    SDG_Draw.draw(
      ctx, draggingCard.image, mouseX - deltaX, mouseY - deltaY, layout.cardWidth, layout.cardHeight,
    );

    if (draggingCard.tableauArray && draggingCard.startPosition) {

      // render the rest of the cards at our x and y pos of the next cards in that tableau array.
      let j = 1; // HACK
      for (let i = draggingCard.startPosition; i < draggingCard.tableauArray.length; i++) {

        SDG_Draw.draw(
          ctx, draggingCard.tableauArray[i].image, mouseX - deltaX, mouseY - deltaY + j * layout.cardYpadding, layout.cardWidth, layout.cardHeight,
        );
        j++;
      }
    }
  },

  /* ---------------------------------------------------------------- */

  // render the explosion when the player wins.
  this.renderGameWinExplosion = async function () {
    await new particles.SOL_Particles(client).confettiCall();
  };

}

