/*
 *  SOL_draggingCards.js
 *  Solitaire
 *
 *  Created by Kim Moran on Aug 31, 2022
 *  Copyright © 2022 Sinclair Digital. - All Rights Reserved
 *
 *  Computes values for beginning, speed, direction, and end of animation. Rendering coming from main.js at 60FPS.
 */



/* ---------------------------------------------------------------- */

export function SOL_Animation(SOL_Object, SOL_Images, SOL_Canvas, CARDS) {

  const client = SOL_Object.client;

  const { stock, waste, foundation, tableau } = client.placement;

  let screenX, screenY;

  /* ---------------------------------------------------------------- */

  // set screen dimensions dynamically!

  this.setscreenXY = function (screenX, screenY) {

    screenX = SOL_Object.client.layout.width;
    screenY = SOL_Object.client.layout.height;

  },

  /* ---------------------------------------------------------------- */

  // compute distance based on to and from

  this.computeDistance = function (x1, y1, x2, y2, speed) {

    const pixelsX2 = Math.pow((x1 - x2), 2);
    const pixelsY2 = Math.pow((y1 - y2), 2);

    const screenDist = Math.sqrt(pixelsX2 + pixelsY2);
    return (screenDist / speed);// gives us a constant value in terms of the number of times we need to animate. Every tick we will move this distance. (Game ticks)
  },

  /* ---------------------------------------------------------------- */

  // update animation for single card.

  this.animateCard = function (card) {

    if (card.animTicks < 0) {
      return;
    }
    client.animate = true;
    const elapsed = client.gameTick - card.startTime; // starts at 0

    if (elapsed < 0) {
      return;
    }  // time of this animation elapsing. If less than 0, exit!

    card.animX = card.xpos + (card.deltaX * (card.animTicks - elapsed) / card.animTicks); // elapsed will never be more than one. The ratio of elapsed to animTicks

    card.animY = card.ypos + (card.deltaY * (card.animTicks - elapsed) / card.animTicks);


    if (card.flip) {

      card.animTicks = client.flipSpeed * 10;
      const halfWayPoint = card.animTicks / 2;

      if (elapsed < halfWayPoint) { // on the first half of the flip, show the back side of the card
        card.up = false;
        card.scaleX = 1 - elapsed / halfWayPoint; // when these get close to equal it'll be close to a zero x-scale.

      } else { // on the other half of the flip, show the top side of the card
        card.up = true;
        card.scaleX = (elapsed / halfWayPoint) - 1;

      }
    }

    if (elapsed > card.animTicks) {
      client.animate = false;
      card.animate = false;
      card.flip = false;

    }

  },

  /* ---------------------------------------------------------------- */

  // we want to move the card from it's source to its destination location. Animation only, the card already knows where it lives.

  this.setCardAnimation = function (speed, card, destX, destY, sourceX, sourceY, delay = 0) {

    card.animate = true;


    card.animX = sourceX ? sourceX : card.xpos;
    card.animY = sourceY ? sourceY : card.ypos;

    card.fromX = !sourceX ? card.xpos : sourceX;
    card.fromY = !sourceY ? card.ypos : sourceY;

    card.delay = delay;
    card.startTime = client.gameTick + delay;

    card.deltaX = card.fromX - destX;
    card.deltaY = card.fromY - destY;

    // card.animTicks = this.computeDistance(card.fromX, card.fromY, destX, destY, speed);
    card.animTicks = card.flip ? speed * 10 : this.computeDistance(card.fromX, card.fromY, destX, destY, speed);
  },

  /* ---------------------------------------------------------------- */

  // we want to deal the cards on the tableau consecutively and beautifully

  this.dealCards = function () {

    let timeDelay = 0;

    for (let i = 0; i < 7; i++) {

      Object.values(tableau).forEach((tabs) => {

        if (tabs[i]) {
          let tableauCard = tabs[i];
          this.setCardAnimation(client.dealSpeed, tableauCard, tableauCard.xpos, tableauCard.ypos, client.layout.stockXpos, client.layout.stockYpos, timeDelay);
          tableauCard.up = false;
          timeDelay += 3;
        }
      });

    }

    if (waste && client.gameMode == client.GAMEMODE.STANDARD) {

      let topWaste = client.placement.waste[client.placement.waste.length - 1];
      // topWaste.up = true;
      topWaste.flip = true;
      timeDelay += 5;
      this.setCardAnimation(client.animSpeed, topWaste, topWaste.xpos, topWaste.ypos, client.layout.stockXpos, client.layout.stockYpos, timeDelay);
    } else if (waste && client.gameMode == client.GAMEMODE.KLONDIKE) {
      const flipCards = [];
      const topWaste = client.placement.waste[client.placement.waste.length - 1];
      const secondWaste = client.placement.waste[client.placement.waste.length - 2];
      const thirdWaste = client.placement.waste[client.placement.waste.length - 3];
      flipCards.push(thirdWaste, secondWaste, topWaste);
      flipCards.forEach((card) => {
        // flipCards.up = true;
        card.up = false;
        card.flip = true;
        timeDelay += 5;
        this.setCardAnimation(client.animSpeed, card, card.xpos, card.ypos, client.layout.stockXpos, client.layout.stockYpos, timeDelay);
      }
      );
    }
  },

  /* ---------------------------------------------------------------- */

  // flipTopCards(): we want to flip the top cards in the waste and tableau

  this.flipTopCards = function () {

    let timeDelay = 10;

    Object.values(SOL_Object.client.placement.tableau).forEach((tab) => {

      if (!tab.length) {
        return;
      }
      // top card in each tableau lives as the last item in the array
      let flipCard = tab[tab.length - 1];
      // TODO! Global object maintaining reference to these and so even with a time delay the card has this 'NEW' flip anim value (xpos/ypos). Tried animx, tried stockx, just that javascript is synchronous. Tried a set timeout , adding to delay. Still reading them.
      timeDelay += 10;
      flipCard.animate = false;
      flipCard.flip = true;

      // setTimeout(()=>{
      this.setCardAnimation(client.flipSpeed, flipCard, flipCard.xpos, flipCard.ypos, flipCard.xpos, flipCard.ypos, timeDelay);
      // },timeDelay)

    });
  },

  /* ---------------------------------------------------------------- */

  // spin and move the cards up to foundation automagically

  this.gameWinAnimation = function () {
    const { fnd1, fnd2, fnd3, fnd4 } = SOL_Object.client.placement.foundation;
    if (fnd1.length < 13 || fnd2.length < 13 || fnd3.length < 13 || fnd4.length < 13) {

      for (let i = 0; i < 52; i++) {
        const card = SOL_Object.client.cards[i];
        if (!card.isOnFoundation) {

          let hoveredCard = CARDS.validateFoundationMove(card);

          if (hoveredCard) {

            SOL_Object.client.winDelay += 5;
            CARDS.moveCards(card, hoveredCard, true);
            this.setCardAnimation(SOL_Object.client.winSpeed, card, hoveredCard.xpos, hoveredCard.ypos, card.xpos, card.ypos, SOL_Object.client.winDelay);
            SOL_Canvas.setCardPositions(SOL_Object.client);
            CARDS.updateFlags(SOL_Object.client.placement);

          }
        }
      }
    }
  },

  /* ---------------------------------------------------------------- */

  // finds the card that has the animation flag, then calls

  this.update = function () {

    for (let i = 0; i < SOL_Object.client.cards.length; i++) {

      const card = SOL_Object.client.cards[i];
      if (card.animate || card.flip) {
        this.animateCard(card);
      }
    }

    if (client.gameState == client.GAMESTATE.WON) {
      this.gameWinAnimation();
    }

  };

}