/*
 *  SOL_Images.js
 *  Solitaire
 *
 *  Created by Kim Moran on Sept 5, 2022
 *  Copyright © 2022 Sinclair Digital. - All Rights Reserved
 *
 *  Utility file for uploading image files and loading them.
 *  TODO: refactor, remove arrow functions, etc.
 */
/* ---------------------------------------------------------------- */

// cards and background, card back, and foundation

export function SOL_Images() {

  this.foundationImg;
  this.cardBackImg;
  this.tableauImg;
  this.emptyImg;

  const images = {
    cardImgs: []
  };
  /* ---------------------------------------------------------------- */

  function getImage(imageName) {
    return images.cardImgs[imageName];
  }
  this.getImage = getImage;

  /* ---------------------------------------------------------------- */

  this.getImageNames = function (imgLibrary, station = 'XTRA') {
    if (imgLibrary == 'common') {

      return [
        `common/backgrounds/solitaire-${station}-background.png`,
        `common/cardbacks/cardback-${station}.png`,
        `common/loading/solitaire-splashscreen-background.png`,
        `common/logos/solitaire-${station}-logo.png`,
        'common/icons/deckSelector-desktop.png',
        'common/icons/deckSelector-mobile.png',
        'common/icons/deckSelector-accessibility.png',
        'common/icons/deckSelector-accessibility1.png',
        'common/icons/closeIcon.svg',
        'common/icons/cog-dim.svg',
        'common/icons/cog-lit.svg',
        'common/icons/cog.svg',
        'common/icons/newgame-dim.svg',
        'common/icons/newgame-lit.svg',
        'common/icons/newgame.svg',
        'common/icons/timer-dim.svg',
        'common/icons/timer.svg',
        'common/icons/trophy-dim.svg',
        'common/icons/trophy-lit.svg',
        'common/icons/trophy.svg',
        'common/icons/undo-dim.svg',
        'common/icons/undo-lit.svg',
        'common/icons/undo.svg',
        'common/icons/user-circle.svg',
        'common/icons/user-dim.svg',
        'common/icons/vol5-dim.svg',
        'common/icons/volume5-lit.svg',
        'common/icons/volume5-off.svg'

      ];
    }

    if (imgLibrary == 'desktop') {

      return [
        // these will likely be coming in from a JSON seeding file from S3, but for now they are stored on local
        'desktop/1-spade.png',
        'desktop/2-spade.png',
        'desktop/3-spade.png',
        'desktop/4-spade.png',
        'desktop/5-spade.png',
        'desktop/6-spade.png',
        'desktop/7-spade.png',
        'desktop/8-spade.png',
        'desktop/9-spade.png',
        'desktop/10-spade.png',
        'desktop/11-spade.png',
        'desktop/12-spade.png',
        'desktop/13-spade.png',
        'desktop/1-heart.png',
        'desktop/2-heart.png',
        'desktop/3-heart.png',
        'desktop/4-heart.png',
        'desktop/5-heart.png',
        'desktop/6-heart.png',
        'desktop/7-heart.png',
        'desktop/8-heart.png',
        'desktop/9-heart.png',
        'desktop/10-heart.png',
        'desktop/11-heart.png',
        'desktop/12-heart.png',
        'desktop/13-heart.png',
        'desktop/1-club.png',
        'desktop/2-club.png',
        'desktop/3-club.png',
        'desktop/4-club.png',
        'desktop/5-club.png',
        'desktop/6-club.png',
        'desktop/7-club.png',
        'desktop/8-club.png',
        'desktop/9-club.png',
        'desktop/10-club.png',
        'desktop/11-club.png',
        'desktop/12-club.png',
        'desktop/13-club.png',
        'desktop/1-diamond.png',
        'desktop/2-diamond.png',
        'desktop/3-diamond.png',
        'desktop/4-diamond.png',
        'desktop/5-diamond.png',
        'desktop/6-diamond.png',
        'desktop/7-diamond.png',
        'desktop/8-diamond.png',
        'desktop/9-diamond.png',
        'desktop/10-diamond.png',
        'desktop/11-diamond.png',
        'desktop/12-diamond.png',
        'desktop/13-diamond.png',
        'desktop/foundationImg.png',
        'desktop/tableauImg.png',
        'desktop/emptyImg.png'
      ];
    }


    if (imgLibrary == 'accessibility') {

      return [
        // these will likely be coming in from a JSON seeding file from S3, but for now they are stored on local
        'accessibility/1-spade.png',
        'accessibility/2-spade.png',
        'accessibility/3-spade.png',
        'accessibility/4-spade.png',
        'accessibility/5-spade.png',
        'accessibility/6-spade.png',
        'accessibility/7-spade.png',
        'accessibility/8-spade.png',
        'accessibility/9-spade.png',
        'accessibility/10-spade.png',
        'accessibility/11-spade.png',
        'accessibility/12-spade.png',
        'accessibility/13-spade.png',
        'accessibility/1-heart.png',
        'accessibility/2-heart.png',
        'accessibility/3-heart.png',
        'accessibility/4-heart.png',
        'accessibility/5-heart.png',
        'accessibility/6-heart.png',
        'accessibility/7-heart.png',
        'accessibility/8-heart.png',
        'accessibility/9-heart.png',
        'accessibility/10-heart.png',
        'accessibility/11-heart.png',
        'accessibility/12-heart.png',
        'accessibility/13-heart.png',
        'accessibility/1-club.png',
        'accessibility/2-club.png',
        'accessibility/3-club.png',
        'accessibility/4-club.png',
        'accessibility/5-club.png',
        'accessibility/6-club.png',
        'accessibility/7-club.png',
        'accessibility/8-club.png',
        'accessibility/9-club.png',
        'accessibility/10-club.png',
        'accessibility/11-club.png',
        'accessibility/12-club.png',
        'accessibility/13-club.png',
        'accessibility/1-diamond.png',
        'accessibility/2-diamond.png',
        'accessibility/3-diamond.png',
        'accessibility/4-diamond.png',
        'accessibility/5-diamond.png',
        'accessibility/6-diamond.png',
        'accessibility/7-diamond.png',
        'accessibility/8-diamond.png',
        'accessibility/9-diamond.png',
        'accessibility/10-diamond.png',
        'accessibility/11-diamond.png',
        'accessibility/12-diamond.png',
        'accessibility/13-diamond.png',
        'accessibility/foundationImg.png',
        'accessibility/tableauImg.png',
        'accessibility/emptyImg.png'

      ];

    }

    if (imgLibrary == 'accessibility1') {


      return [
        // these will likely be coming in from a JSON seeding file from S3, but for now they are stored on local
        'accessibility1/1-spade.png',
        'accessibility1/2-spade.png',
        'accessibility1/3-spade.png',
        'accessibility1/4-spade.png',
        'accessibility1/5-spade.png',
        'accessibility1/6-spade.png',
        'accessibility1/7-spade.png',
        'accessibility1/8-spade.png',
        'accessibility1/9-spade.png',
        'accessibility1/10-spade.png',
        'accessibility1/11-spade.png',
        'accessibility1/12-spade.png',
        'accessibility1/13-spade.png',
        'accessibility1/1-heart.png',
        'accessibility1/2-heart.png',
        'accessibility1/3-heart.png',
        'accessibility1/4-heart.png',
        'accessibility1/5-heart.png',
        'accessibility1/6-heart.png',
        'accessibility1/7-heart.png',
        'accessibility1/8-heart.png',
        'accessibility1/9-heart.png',
        'accessibility1/10-heart.png',
        'accessibility1/11-heart.png',
        'accessibility1/12-heart.png',
        'accessibility1/13-heart.png',
        'accessibility1/1-club.png',
        'accessibility1/2-club.png',
        'accessibility1/3-club.png',
        'accessibility1/4-club.png',
        'accessibility1/5-club.png',
        'accessibility1/6-club.png',
        'accessibility1/7-club.png',
        'accessibility1/8-club.png',
        'accessibility1/9-club.png',
        'accessibility1/10-club.png',
        'accessibility1/11-club.png',
        'accessibility1/12-club.png',
        'accessibility1/13-club.png',
        'accessibility1/1-diamond.png',
        'accessibility1/2-diamond.png',
        'accessibility1/3-diamond.png',
        'accessibility1/4-diamond.png',
        'accessibility1/5-diamond.png',
        'accessibility1/6-diamond.png',
        'accessibility1/7-diamond.png',
        'accessibility1/8-diamond.png',
        'accessibility1/9-diamond.png',
        'accessibility1/10-diamond.png',
        'accessibility1/11-diamond.png',
        'accessibility1/12-diamond.png',
        'accessibility1/13-diamond.png',
        'accessibility1/foundationImg.png',
        'accessibility1/tableauImg.png',
        'accessibility1/emptyImg.png'

      ];

    }

    if (imgLibrary == 'mobile') {

      return [
        'mobile/1-spade.png',
        'mobile/2-spade.png',
        'mobile/3-spade.png',
        'mobile/4-spade.png',
        'mobile/5-spade.png',
        'mobile/6-spade.png',
        'mobile/7-spade.png',
        'mobile/8-spade.png',
        'mobile/9-spade.png',
        'mobile/10-spade.png',
        'mobile/11-spade.png',
        'mobile/12-spade.png',
        'mobile/13-spade.png',
        'mobile/1-heart.png',
        'mobile/2-heart.png',
        'mobile/3-heart.png',
        'mobile/4-heart.png',
        'mobile/5-heart.png',
        'mobile/6-heart.png',
        'mobile/7-heart.png',
        'mobile/8-heart.png',
        'mobile/9-heart.png',
        'mobile/10-heart.png',
        'mobile/11-heart.png',
        'mobile/12-heart.png',
        'mobile/13-heart.png',
        'mobile/1-club.png',
        'mobile/2-club.png',
        'mobile/3-club.png',
        'mobile/4-club.png',
        'mobile/5-club.png',
        'mobile/6-club.png',
        'mobile/7-club.png',
        'mobile/8-club.png',
        'mobile/9-club.png',
        'mobile/10-club.png',
        'mobile/11-club.png',
        'mobile/12-club.png',
        'mobile/13-club.png',
        'mobile/1-diamond.png',
        'mobile/2-diamond.png',
        'mobile/3-diamond.png',
        'mobile/4-diamond.png',
        'mobile/5-diamond.png',
        'mobile/6-diamond.png',
        'mobile/7-diamond.png',
        'mobile/8-diamond.png',
        'mobile/9-diamond.png',
        'mobile/10-diamond.png',
        'mobile/11-diamond.png',
        'mobile/12-diamond.png',
        'mobile/13-diamond.png',
        'mobile/foundationImg.png',
        'mobile/tableauImg.png',
        'mobile/emptyImg.png',
      ];

    }
  },
  /* ---------------------------------------------------------------- */

  // downloads one image if it is not in the cardImgs array
  this.downloadImage = function downloadImage(imageName) {
    if(images.cardImgs[imageName] ){
      return Promise.resolve();
    }
    return new Promise((resolve) => {
      const newImage = new Image();
      newImage.onload = () => {
        images.cardImgs[imageName] = newImage;
        resolve();
      };
      newImage.src = `/assets/${imageName}`;
    });
  },
  // runs on game initialization to prep all of our images for easy use!

  this.downloadImages = function (imgLibrary, station) {
    const imageNames = this.getImageNames(imgLibrary, station);
    if (!imageNames) {
      return;
    }

    return Promise.all(imageNames.map(this.downloadImage));
  },
  /* ---------------------------------------------------------------- */

  // only runs when called by activateGame
  this.initImages = function (cardChoice, station) {
    this.foundationImg = getImage(`${cardChoice}/foundationImg.png`);
    this.cardBackImg = getImage(`common/cardbacks/cardback-${station}.png`);
    this.tableauImg = getImage(`${cardChoice}/tableauImg.png`);
    this.emptyImg = getImage(`${cardChoice}/emptyImg.png`);
  };
}
