/*
 *  SOL_Card.js
 *  Solitaire
 *
 *  Created by Kim Moran and Kieran Hannagan on Sept 19th, 2022
 *  Copyright © 2022 Sinclair Digital. - All Rights Reserved
 *
 *  Do all the things with all the cards.
 */


/* ---------------------------------------------------------------- */
export class Cards {

  constructor(client) {

    const cards = client.cards;
    const layout = client.layout;

    // initializes a 52 card deck with card properties.

    this.initializeDeck = function (SOL_Images) {
      // const playerDevice = client.playerDevice

      const SUITS = ['heart', 'diamond', 'spade', 'club']; // all suits of card
      const NUMBERS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]; // all numeric values of card
      // let j = 0;
      // probably going to add in a 'mobile' vs desktop to file name since we'll be using all svg's. But for now we can use this for testing these two limited environments. Same with card back. i.e. Check site url of parent iFrame to determine which cardBackground and main background image / logo to render.

      // let c = (client.playerDevice == 'mobile') ? 'mobile' : 'desktop'
      cards.length = 0;
      for (let suitValue = 0; suitValue < SUITS.length; suitValue++) {
        // create deck of 52 deck
        for (let i = 0; i < NUMBERS.length; i++) {
          const card = {
            name: NUMBERS[i] + ' of ' + SUITS[suitValue],
            suit: SUITS[suitValue],
            value: NUMBERS[i],
            cardNum: (suitValue * 13) + i, // derive value from 1-52
            up: false,
            isDragging: false,
            child: true,
            xpos: 0, // x position of upper left hand corner of card
            ypos: 0, // y position of upper left hand corner of card
            isOnFoundation: false, // not sure these are needed
            isOnTableau: false, // not sure these are needed
            isInStock: false, // not sure these are needed
            isInWaste: false, // not sure these are needed
            animate: false,
            flip: false,
            animX: 0, // when animate is true we render using anim x and y
            animY: 0,
            scaleX: 1, // when flip is true we render using scale x and y
            scaleY: 1,
            animUp: false,
            delay: 0,
            deltaX: 0,
            deltaY: 0,
            animTicks: 0, // number of ticks it will take to animate the card to its' destination (see computeVelocity())
            src: `${client.cardChoice}/${NUMBERS[i]}-${SUITS[suitValue]}.png`, // setting all cards to be face down by default
            image: SOL_Images.getImage(`${client.cardChoice}/${NUMBERS[i]}-${SUITS[suitValue]}.png`),
            tableauArray: null,
            startPosition: null,
          };

          cards.push(card);
        }
        // ! NOTE: might want to initialize the images here
      }

      // assign faces and colors
      for (let i = 0; i < cards.length; i++) {
        // assigning colors and faces
        // cards[i].index = i; // TODO: index looks to be the same as cardNum, possibly factor out

        const value = (i % 13) + 1;                                 // card value is always one more than the remainder of index / 13

        if (i < 26) {
          cards[i].color = 'red';
        } else {
          cards[i].color = 'black';
        }

        switch (value) {
        case 1:
          cards[i].face = 'ace';
          break;
        case 11:
          cards[i].face = 'jack';
          break;
        case 12:
          cards[i].face = 'queen';
          break;
        case 13:
          cards[i].face = 'king';
          break;
        default:
          cards[i].face = value;
        }
      }
      return cards;
    },

    /* ---------------------------------------------------------------- */

    // Takes an array of 52 cards and shuffles them thoroughly.

    this.shuffle = function (seed) {
      for (let i = cards.length - 1; i >= 0; i--) {
        let random = Math.floor(Math.random() * cards.length);
        seed[i] = random;
        let temp = cards[i];
        cards[i] = cards[random];
        cards[random] = temp;
      }

      let reversedSeed = seed.reverse(); // reversing the seed we have so we can iterate if we use it

      return reversedSeed;
    },

    /* ---------------------------------------------------------------- */

    // assign images to cards based on screen size and other dynamic future factors, like city!

    this.setImageType = async function (SOL_Images, cardChoice) {

      // return early if the cards have not been initialized
      if (cards.length == 0) {
        return;
      }

      await SOL_Images.downloadImages(cardChoice);

      for (let i = 0; i < 52; i++) {
        const card = cards[i];
        card.src = `${cardChoice}/${card.value}-${card.suit}.png`; // setting all cards to be face down by default
        card.image = SOL_Images.getImage(`${cardChoice}/${card.value}-${card.suit}.png`);
      }
    },

    /* ---------------------------------------------------------------- */

    // if a restart game is called, playSeed instead of shuffle.

    this.playSeed = async function (seed) {
      cards = [];
      for (let i = cards.length - 1; i >= 0; i--) {
        let random = seed[i];
        let temp = cards[i];
        cards[i] = cards[random];
        cards[random] = temp;
      }
      return seed;
    },

    /* ---------------------------------------------------------------- */

    // assign cards to proper places in deck and tableau.

    this.deal = function (placement) {
      // card placement on tableau, dealing horizontally
      Object.values(placement.tableau).forEach(tab => tab.length = 0);
      Object.values(placement.foundation).forEach(found => found.length = 0);
      placement.waste = [];
      placement.stock = [];


      placement.tableau.tab1.push(cards[0]);
      placement.tableau.tab2.push(cards[1]);
      placement.tableau.tab3.push(cards[2]);
      placement.tableau.tab4.push(cards[3]);
      placement.tableau.tab5.push(cards[4]);
      placement.tableau.tab6.push(cards[5]);
      placement.tableau.tab7.push(cards[6]);
      cards[0].up = true;

      placement.tableau.tab2.push(cards[7]);
      placement.tableau.tab3.push(cards[8]);
      placement.tableau.tab4.push(cards[9]);
      placement.tableau.tab5.push(cards[10]);
      placement.tableau.tab6.push(cards[11]);
      placement.tableau.tab7.push(cards[12]);
      cards[7].up = true;

      placement.tableau.tab3.push(cards[13]);
      placement.tableau.tab4.push(cards[14]);
      placement.tableau.tab5.push(cards[15]);
      placement.tableau.tab6.push(cards[16]);
      placement.tableau.tab7.push(cards[17]);
      cards[13].up = true;

      placement.tableau.tab4.push(cards[18]);
      placement.tableau.tab5.push(cards[19]);
      placement.tableau.tab6.push(cards[20]);
      placement.tableau.tab7.push(cards[21]);
      cards[18].up = true;

      placement.tableau.tab5.push(cards[22]);
      placement.tableau.tab6.push(cards[23]);
      placement.tableau.tab7.push(cards[24]);
      cards[22].up = true;

      placement.tableau.tab6.push(cards[25]);
      placement.tableau.tab7.push(cards[26]);
      cards[25].up = true;

      placement.tableau.tab7.push(cards[27]);
      cards[27].up = true;
      // placement on waste and stock

      for (let i = 0; i < 28; i++) {
        cards[i].isOnTableau = true; // assign all these dealt cards to the 'isInTableau
      }

      const gameMode = client.gameMode;

      if (gameMode == client.GAMEMODE.STANDARD) {
        placement.waste.push(cards[28]);
        cards[28].up;
        cards[28].isInWaste = true;

        for (let i = 29; i < 52; i++) {
          placement.stock.push(cards[i]);
          cards[i].isInStock = true; // assign all these cards (29-51) to the 'isInStock' category.
        }
      } else {
        client.wasteCardCount = 3;
        placement.waste.push(cards[28]);
        placement.waste.push(cards[29]);
        placement.waste.push(cards[30]);

        cards[28].up = true;
        cards[29].up = true;
        cards[30].up = true;

        cards[28].isInWaste = true;
        cards[29].isInWaste = true;
        cards[30].isInWaste = true;

        for (let i = 31; i < 52; i++) {
          placement.stock.push(cards[i]);
          cards[i].isInStock = true; // assign all these cards (29-51) to the 'isInStock' category.
        }

      }

      return cards;

    },

    /* ---------------------------------------------------------------- */

    // returns card under cursor x,y position.

    this.findHovered = function (xpos, ypos, deltaX = null, deltaY = null) {

      let hoveredCard = null;

      if (client.placement.stock.length) { // check there are cards in the stock to click on

        if (this.checkPosition(client.placement.stock[client.placement.stock.length - 1], xpos, ypos)) {
          hoveredCard = client.placement.stock[client.placement.stock.length - 1];
        }
      }
      if (!client.placement.stock.length) { // check if there's an empty stock
        if (this.checkPosition(client.emptyStockSlot, xpos, ypos)) {
          hoveredCard = client.emptyStockSlot;
        }
      }


      if (client.placement.waste.length) { // don't check waste if it is empty! Errors out the game
        if (this.checkPosition(client.placement.waste[client.placement.waste.length - 1], xpos, ypos)) {
          hoveredCard = client.placement.waste[client.placement.waste.length - 1];
        }
      }

      if (!hoveredCard) {

        Object.values(client.placement.tableau).forEach((tab) => {
          // nested for loop to go through each item on tableau

          if (hoveredCard) {
            return;
          }

          let tableauCard;

          for (let j = tab.length - 1; j >= 0; j--) {
            tableauCard = tab[j];

            if (client.draggingCard) {
              if (tableauCard.up) {
                if (this.cardZoneDetection(tableauCard, xpos, ypos, deltaX, deltaY)) {
                  hoveredCard = tableauCard;
                  return;
                }
              }
            }
            if (!hoveredCard) {
              if (this.checkPosition(tableauCard, xpos, ypos)) {
                hoveredCard = tableauCard;
                return;
              }
            }
          }
        });
      }

      if (!hoveredCard) {
        Object.values(client.placement.foundation).forEach((found) => {
          // nested for loop to go through each item on foundation
          if (hoveredCard) {
            return;
          }

          let foundCard;
          for (let j = found.length - 1; j >= 0; j--) {
            foundCard = found[j];
            if (client.draggingCard) {
              if (this.cardZoneDetection(foundCard, xpos, ypos, deltaX, deltaY)) {
                hoveredCard = foundCard;
                return;
              }
            }
            if (!hoveredCard) {
              if (this.checkPosition(foundCard, xpos, ypos)) {
                hoveredCard = foundCard;
                return;
              }
            }
          }
        });
      }

      if (!hoveredCard) { // check empty foundation slots
        for (let i = 0; i < 4; i++) {
          if (client.draggingCard) {
            if (this.cardZoneDetection(client.emptyFoundationSlots[i], xpos, ypos, deltaX, deltaY)) {
              hoveredCard = client.emptyFoundationSlots[i];
              break;
            }
          }
          if (!hoveredCard) {
            if (this.checkPosition(client.emptyFoundationSlots[i], xpos, ypos)) {
              hoveredCard = client.emptyFoundationSlots[i];
              break;
            }
          }
        }
      }

      if (!hoveredCard) { // check empty tableau slots

        for (let i = 0; i < 7; i++) {
          if (client.draggingCard) {
            if (this.cardZoneDetection(client.emptyTableauSlots[i], xpos, ypos, deltaX, deltaY)) {
              hoveredCard = client.emptyTableauSlots[i];
              break;
            }
          } else {
            if (!hoveredCard) {
              if (this.checkPosition(client.emptyTableauSlots[i], xpos, ypos)) {
                hoveredCard = client.emptyTableauSlots[i];
                break;
              }
            }
          }
        }
      }
      return hoveredCard;

    },

    /* ---------------------------------------------------------------- */

    // takes in a card (see 'find card') and checks if the mouse coordinates are inside of the card, returns boolean.

    this.checkPosition = function (card, mouseX, mouseY) {
      if (
        mouseX > card.xpos &&
                    mouseX < (card.xpos + layout.cardWidth) &&
                    mouseY > card.ypos &&
                    mouseY < (card.ypos + layout.cardHeight)
      ) {
        return true;
      }

    },

    /* ---------------------------------------------------------------- */

    // takes in a card (see 'find card') and checks if the dragging card center is within desired range of hovered card's center, returns boolean.

    this.cardZoneDetection = function (card, mouseX, mouseY, deltaX, deltaY) {

      let dragCenterX = (mouseX - deltaX) + (layout.cardWidth / 2);
      let dragCenterY = (mouseY - deltaY) + (layout.cardHeight / 2);
      let hoverCenterX = card.xpos + (layout.cardWidth / 2);
      let hoverCenterY = card.ypos + (layout.cardHeight / 2);

      if ((Math.abs(dragCenterX - hoverCenterX) < (layout.cardWidth / 2)) && (Math.abs(dragCenterY - hoverCenterY) < (layout.cardHeight / 2))) {
        return true;
      }
    };


    /* ---------------------------------------------------------------- */

    // if the stock is empty, moves the waste cards to the stock.

    this.flipWasteDeckToStock = function () {

      let testWaste = client.placement.waste.length;

      for (let i = client.placement.waste.length; i > 0; i--) { // iterate through each waste card and put it back in the stock, in the opposite order (top waste becomes bottom of the stock now)

        client.placement.stock.push(client.placement.waste.pop());
      }

      let testStock = client.placement.stock.length;

      if (testWaste == testStock) {
        return;
      }
      return false;

    },

    /* ---------------------------------------------------------------- */

    // handle flipping the stock card to the waste card. Currently set to one card at a time.

    this.flipStockToWaste = function (stock, waste, flipCards) {

      function flip() {
        let newWasteCard = stock.pop();                                 // remove last card from stock stack :)
        waste.push(newWasteCard);                                       // add it to the waste array!

      }

      if (client.gameMode == client.GAMEMODE.STANDARD) {
        flipCards.push(client.hoveredCard);
        flip();
        return flipCards;
      } else {
        if (stock.length >= 3 && (client.wasteCardCount != 0)) {
          // if I flip stock to waste then the wasteCardCount is back up to three. Unless there are only 2 or 1 cards left in stock
          client.wasteCardCount = 3;
          flipCards.push(stock[stock.length - 3], stock[stock.length - 2], client.hoveredCard);
          flip();
          flip();
          flip();
          return flipCards;
        }

        if (stock.length == 2 && client.wasteCardCount != 0) {
          client.wasteCardCount = 2;
          flipCards.push(stock[stock.length - 2], client.hoveredCard);
          flip();
          flip();
          return flipCards;

        }
        if (stock.length == 1 && client.wasteCardCount != 0) {
          client.wasteCardCount = 1;
          flipCards.push(client.hoveredCard);
          flip();
          return flipCards;
        }
      }


      // client.animation.setCardAnimation(newWasteCard, newWasteCard.xpos, newWasteCard.ypos, client.layout.stockXpos, client.layout.stockYpos);
    },

    /* ---------------------------------------------------------------- */

    // returns the start position of the card after our current card and converts the child flag to false.

    this.findStartPosition = function (tableauArray) {

      // finds which card is the draggingCard in this current tableau array. Returns the next card, assigns 'child: false' flag.
      let startPos = 0;
      for (let i = 0; i < tableauArray.length; i++) {

        if (tableauArray[i].cardNum == client.draggingCard.cardNum) { // if the cardNum from our dragging card matches the one in this array, return the next card index.
          if (tableauArray.length > 1 && (i <= tableauArray.length - 2)) { // was erroring out on the search through empty arrays and arrays with only 1 card in them (can't be dragging if only one current card)
            startPos = i + 1;
            for (let i = startPos; i < tableauArray.length; i++) {
              tableauArray[i].child = false; // update flag to false for rendering for each of the cards we are dragging.
            }
          }
        }
      }
      return startPos;

    },

    /* ---------------------------------------------------------------- */

    //ensures that the front card is the same as dragging card for placement on foundation, whether it's in waste or on tableau
    (this.validateFrontCard = function (card) {
      //check if last card on foundation so user can move cards from foundation back to tableau if needed
      let foundation = client.placement.foundation;
      for (let fndKey in foundation) {
        const fndCard = foundation[fndKey];
        if (fndCard.length === 0) {
          continue;
        }
        if (card.value === fndCard.value && card.suit === fndCard.suit) {
          return true;
        }
      }
      //check if last card in waste (in case of Klondike 3 card version)
      let waste = client.placement.waste;
      if (waste.length > 0) {
        if (
          card.value === waste[waste.length - 1].value &&
          card.suit === waste[waste.length - 1].suit
        ) {
          return true;
        }
      }
      let allTabsObj = client.placement.tableau;
      // access the tab key arrays within tableau object
      for (let tabKey in allTabsObj) {
        const tab = allTabsObj[tabKey];
        if (tab.length === 0) {
          continue;
        }
        let frontCard = tab[tab.length - 1];
        if (card.value === frontCard.value && card.suit === frontCard.suit) {
          return true;
        }
      }
      return false;
    }),

    // returns true or false based on the dragging card and hovering card under clicked x,y position.

    this.validateMove = function (draggingCard, hoveredCard) {

      // if it's an ace, will return true, but we need the emptyFound checked because the hoveredCard becomes taht name.
      if (draggingCard.value == 1) {
        for (let i = 0; i < 4; i++) {
          if (hoveredCard.name == `emptyFound${i + 1}`) {
            return true;
          }
        }
      }

      if (                                                                  // check if it is a card that can go on the foundation
        ((draggingCard.isInWaste || draggingCard.isOnTableau) && hoveredCard.isOnFoundation) &&
                    (draggingCard.value == hoveredCard.value + 1) &&
                    (draggingCard.suit == hoveredCard.suit)
      ) {

        return true;
      }

      // check if there is a king and we have an empty tableau spot for it

      if (draggingCard.value == 13) {
        for (let i = 0; i < 7; i++) {
          if (hoveredCard.name == `emptyTab${i + 1}`) {
            return true;
          }
        }
      }

      if (draggingCard.up && hoveredCard.up) { // both cards need to be up in order to move one to another
        if ( // can I move selected card to tableau - dragging card must be one number lower + opposite color from the landing card.
          (draggingCard.isOnTableau || draggingCard.isInWaste || draggingCard.isOnFoundation) && !hoveredCard.isOnFoundation && !hoveredCard.isInWaste &&
                        draggingCard.value == hoveredCard.value - 1 &&
                        draggingCard.color != hoveredCard.color
        ) {
          return true;
        }
      }
      return false;
    },

    /* ---------------------------------------------------------------- */

    // takes in the dragging (double clicked) card and searches through foundation for a match. Returns the valid hoveredCard for the move

    this.validateFoundationMove = function (draggingCard) {

      // check foundation that has cards on it.
      let validMove = false;
      let hoveredCard = null;

      Object.values(client.placement.foundation).forEach((found, i) => {
        if (!found.length && draggingCard.value == 1) { // if foundation is empty and we have a 1, the hoveredCard becomes this empty foundation slot.

          hoveredCard = client.emptyFoundationSlots[i];
          validMove = true;
          return;
        } else if (found.length) { // if we have cards on the foundation then it's time to check the top one of each and see if it's a valid suit/number by calling validate move.
          // test the top foundation card on each stack
          let testFoundationCard = (found[found.length - 1]);
          validMove = (draggingCard.value === testFoundationCard.value + 1) &&
                            (draggingCard.suit == testFoundationCard.suit);

          if (validMove) { // assign the hoveredCard now.
            hoveredCard = testFoundationCard;
            return;
          }
        } else {
          hoveredCard = null;
        }
      });

      return hoveredCard;
    },

    /* ---------------------------------------------------------------- */

    // if no valid move, return cards to their OG greatness.

    this.resetTableauArray = function (draggingCard) {

      if (draggingCard.startPosition && draggingCard.tableauArray.length) {
        for (let i = draggingCard.startPosition; i < draggingCard.tableauArray.length; i++) {
          draggingCard.tableauArray[i].child = true;
        }

        draggingCard.tableauArray = null; // reset these values since there is no valid move performed.
        draggingCard.startPosition = null;
      }
    },

    /* ---------------------------------------------------------------- */

    // we want to move the dragging card from it's tableau array to the hovered cards tableau array.

    this.moveCards = function (draggingCard, hoveredCard, animate = false) {

      let source = this.findCard(draggingCard);  // returns source array.

      const destination = this.findCard(hoveredCard); // returns destination array

      if (client.gameState == client.GAMESTATE.WON) {
        // different action needed for winning state. Sending all cards to foundation, don't need to worry about tableau array or which cards are underneath
        if (draggingCard.isInWaste) {
          source = client.placement.waste;
        }

        if (draggingCard.isInStock) {
          source = client.placement.stock;
        }

        if (source.length >= 1) {
          for (let i = 0; i < source.length; i++) {
            if (source[i].cardNum == draggingCard.cardNum) {
              source = source.splice(i, 1);
            }
          }
          destination.push(draggingCard);
          return true;
        }

        return false;
      } else {
        // make sure the card doesn't already exist on this tableau
        for (let i = 0; i < destination.length; i++) {

          if (destination[i].up &&
                            (draggingCard.value == destination[i].value) && (draggingCard.color == destination[i].color)) {
            return false;
          }
        }

        if (draggingCard.isOnTableau && source.length >= 2) {
          let flipCard = source[source.length - 2];
          if (draggingCard.startPosition) {
            flipCard = source[draggingCard.startPosition - 2];
          }
          if (flipCard && !flipCard.up) {
            flipCard.flip = true;
            client.animation.setCardAnimation(client.flipSpeed, flipCard, flipCard.xpos, flipCard.ypos, flipCard.xpos, flipCard.ypos);
          }
        }

        if (!draggingCard.tableauArray || !draggingCard.startPosition) {

          const lastCard = source.pop(); // remove 'dragging card' from the source
          destination.push(lastCard); // push it onto the destination
          if (draggingCard.isInWaste) {
            if (client.wasteCardCount > 1) {
              client.wasteCardCount--;
            } else if (client.wasteCardCount == 1) {
              client.wasteCardCount == 3;
            }

          }
          if (animate) {
            client.animation.setCardAnimation(client.animSpeed, lastCard, hoveredCard.xpos, hoveredCard.ypos);
          }
          return true;
        } else { // we could use the tableauArray for all of our updates here, but it's cleaner to use/update the source array we already have from the conditional.

          const tempArray = [];
          const difference = draggingCard.tableauArray.length - draggingCard.startPosition;
          let lastCard;

          for (let i = 0; i <= difference; i++) {

            lastCard = source.pop(); // remove the top card from the source array (low number)
            tempArray.push(lastCard);

          }
          // if (tempArray.length == difference){ // only moves cards to destination if all our cards were successfully pushed.
          for (let i = 0; i < tempArray.length; i++) {

            destination.push(tempArray[tempArray.length - [i + 1]]);

          }

          draggingCard.tableauArray = null; // reset these values after move is done.
          draggingCard.startPosition = null;
          return true;
        }
      }
    },

    /* ---------------------------------------------------------------- */

    // will return the array for a card passed in.

    this.findCard = function (card) {

      const { tableau, foundation, waste, stock } = client.placement;

      let returnedArray = null;

      // first check waste
      if (waste.length) {
        if (card.cardNum == waste[waste.length - 1].cardNum) {
          return waste;
        }
      }


      // return empty foundation based on emptyFoundation name. Expected to return the emptyFoundationSlot array
      // check foundation that has cards on it.

      Object.values(foundation).forEach((foundColumn, i) => {
        if (returnedArray) {
          return;
        }
        // nested for loop to go through each item on foundation
        if (card.name == `emptyFound${i + 1}`) {
          returnedArray = foundColumn;
          // return returnedArray;
        }
      });

      // check foundation that has cards on it.

      Object.values(foundation).forEach((found) => {
        if (returnedArray) {
          return;
        }
        // nested for loop to go through each item on foundation
        for (let j = 0; j < found.length; j++) {

          if (found[j].cardNum == card.cardNum) {
            returnedArray = found;
            // return returnedArray;
          }
        }
      });

      // return empty tableau based on emptyTableau name. Expected to return the emptyTableauSlot array
      Object.values(tableau).forEach((tab, i) => {
        if (returnedArray) {
          return;
        }
        // nested for loop to go through each item on foundation
        if (card.name == `emptyTab${i + 1}`) {
          returnedArray = tab;
          // return returnedArray;
        }
      });

      Object.values(tableau).forEach((tab, i) => {
        if (returnedArray) {
          return;
        }
        // nested for loop to go through each item on tableau
        for (let j = 0; j < tab.length; j++) {

          if (tab[j].cardNum == card.cardNum) {
            returnedArray = tab;
            // return returnedArray;
          }
        }
      });


      return returnedArray;
    },

    /* ---------------------------------------------------------------- */

    // will update the flags for our cards after a placement update.

    this.updateFlags = function (placement) {
      // this.updateStockFlags = function (stock) { // not sure what we'll be passing in yet, just using the most narrow for now.
      const { stock, waste, foundation, tableau } = placement;
      // stock cards need 'up' set to false, because we take the waste deck back over to stock when stock is exhausted.

      let stockCard;
      let foundCard;
      for (let i = 0; i < stock.length; i++) {

        stockCard = stock[i];
        stockCard.up = false;
        stockCard.isInStock = true;
        stockCard.isInWaste = false;
        stockCard.isOnFoundation = false;
        stockCard.isOnTableau = false;

      }

      // isInWaste true, all else false. Waste cards are always up.
      let wasteCard;
      for (let i = 0; i < waste.length; i++) {
        wasteCard = waste[i];
        wasteCard.up = true;
        wasteCard.isInStock = false;
        wasteCard.isInWaste = true;
        wasteCard.isOnFoundation = false;
        wasteCard.isOnTableau = false;

      }

      // Foundation cards are always up

      Object.values(foundation).forEach((foundColumn, i) => {
        for (let j = 0; j < foundColumn.length; j++) {

          foundCard = foundColumn[j];
          foundCard.isInStock = false;
          foundCard.isInWaste = false;
          foundCard.isOnFoundation = true;
          foundCard.isOnTableau = false;

        }
      });

      // this.updateTableauFlags = function (tableau) {

      let tableauCard;
      Object.values(tableau).forEach((tab, i) => {
        // every top card on a tableau array is always up, no exceptions. We can never flip a card to it's background on the tableau. They start at false during the deal.
        for (let j = 0; j < tab.length; j++) {
          tableauCard = tab[j];
          tableauCard.isInStock = false;
          tableauCard.isInWaste = false;
          tableauCard.isOnFoundation = false;
          tableauCard.isOnTableau = true;
          if (j == tab.length - 1) { // after a tableau to tableau move, the card facing down needs to be flipped!
            tableauCard.up = true;
          }
          tableauCard.child = true;
          tableauCard.startPosition = 0;
          tableauCard.tableauArray = [];

        }
      });
      return placement;
    };
  }
}

