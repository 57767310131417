/*
 *  SDG_Draw.js
 *  Gaming - libs
 *
 *  Created by Kim Moran on Sept 11, 2022
 *  Copyright © 2022 Sinclair Digital - All Rights Reserved
 *
 *  Draw functionality for basic drawing and the more complicated 9 parameter draw, see https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/drawImage for param reference.
 */

/* ---------------------------------------------------------------- */

export function SDG_Draw() {

  /* ---------------------------------------------------------------- */

  // clears the canvas passed in, starting at 0,0
  this.clear = function (canvasContext, totalWidth, totalHeight) {
    canvasContext.clearRect(0, 0, totalWidth, totalHeight);
  },

  /* ---------------------------------------------------------------- */

  // Draws image based on 5 parameters
  this.draw = function (canvasContext, image, dx, dy, dWidth, dHeight) {
    canvasContext.drawImage(image, dx, dy, dWidth, dHeight);
  },

  /* ---------------------------------------------------------------- */

  // Draws image based on 9 parameters (source x/y, source width/height added)

  this.drawSourceAndDestination = function (

    canvasContext,
    image,
    sx,
    sy,
    sWidth,
    sHeight,
    dx,
    dy,
    dWidth,
    dHeight
  ) {
    canvasContext.drawImage(
      image,
      sx,
      sy,
      sWidth,
      sHeight,
      dx,
      dy,
      dWidth,
      dHeight
    );
  },

  /* ---------------------------------------------------------------- */

  // Draws a shadow based on the image based in. Requires color, blur, and offset parameters

  this.drawShadow = function (canvasContext, image, dx, dy, dWidth, dHeight, color, blur, offsetX, offsetY) {
    canvasContext.drawImage(image, dx, dy, dWidth, dHeight);
    canvasContext.shadowColor = color;
    canvasContext.shadowBlur = blur;
    canvasContext.shadowOffsetX = offsetX;
    canvasContext.shadowOffsetY = offsetY;

  },

  /* ---------------------------------------------------------------- */

  // Draws a 70% opacity shadow based on the image based in. Requires color, blur, and offset parameters

  this.drawShadowCard = function (canvasContext, src, dx, dy, dWidth, dHeight, color, blur, offsetX, offsetY) {
    canvasContext.globalAlpha = .8;
    canvasContext.drawImage(src, dx, dy, dWidth, dHeight);
    canvasContext.shadowColor = color;
    canvasContext.shadowBlur = blur;
    canvasContext.shadowOffsetX = offsetX;
    canvasContext.shadowOffsetY = offsetY;

  },

  /* ---------------------------------------------------------------- */

  // Draws a loading bar, percentage is the color loaded

  this.drawLoadingBar = function (canvasContext, percentage, baseColor, progressColor, dx, dy, dWidth, dHeight) {
    canvasContext.fillStyle = baseColor;
    canvasContext.fillRect(dx, dy, dWidth, dHeight); // starting xpos, starting ypos, total width to draw, total height to draw
    canvasContext.fillStyle = progressColor;
    canvasContext.fillRect(dx, dy, percentage* dWidth/100, dHeight);
  },

  /* ---------------------------------------------------------------- */

  // Draws a shape :)

  this.drawShape = function (canvasContext, baseColor, dx, dy, dWidth, dHeight) {
    canvasContext.fillStyle = baseColor;
    canvasContext.fillRect(dx, dy, dWidth, dHeight); // starting xpos, starting ypos, total width to draw, total height to draw

  };

  /* ---------------------------------------------------------------- */

  // transforms something based on the scale/skews
  this.transform = function (canvasContext, image, scaleX, skewX, skewY, scaleY, translateX, translateY) {
    canvasContext.setTransform(scaleX, skewX, skewY, scaleY, translateX, translateY);
    canvasContext.drawImage(image, 0, 0);

  };

}
