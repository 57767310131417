/*
 *  SOL_ActivatedGameState.js
 *  Solitaire
 *
 *  Created by Jason Bolton on Aug 1, 2023
 *  Copyright © 2023 Sinclair Digital. - All Rights Reserved
 *
 *  class to cleanup resources and callbacks used by an activated game
 */
/* ---------------------------------------------------------------- */

export class SOL_ActivatedGameState {
  constructor() {
    this.intervals = [];
  }
  cleanupOnExit(){
    this.intervals.forEach((each)=>clearInterval(each));
  }
  addIntervalByFPS(func,fps=60){
    this.intervals.push(setInterval(func, 1000 / fps));
  }
}
