/*
 *  SOL_Canvas.js
 *  Solitaire
 *
 *  Created by Kim Moran on Aug 22, 2022
 *  Copyright © 2022 Sinclair Digital. - All Rights Reserved
 *
 *  Define canvas, layout, resize, render board functions
 */
/* ---------------------------------------------------------------- */
export function SOL_Canvas() {

  // needs to be considered for layouts, cards, etc to be drawn correctly
  // set the layout in the global solObject. Allows us to draw proper layout for the cards and background.
  // canvas definitions from the dom
  const canvas = document.getElementById('gameCanvas'); //Declare and define our game canvas and context
  this.canvas = canvas;
  const ctx = canvas.getContext('2d');
  this.ctx = ctx;

  /* ---------------------------------------------------------------- */

  // backcanvas definitions from the dom

  const backCanvas = document.getElementById('backCanvas');
  this.backCanvas = backCanvas;
  const bctx = backCanvas.getContext('2d');
  this.bctx = bctx;

  /* ---------------------------------------------------------------- */

  // animation canvas definitions from the dom

  const animCanvas = document.getElementById('animationCanvas');
  this.animCanvas = animCanvas;
  const animctx = animCanvas.getContext('2d');
  this.animctx = animctx;

  // animation canvas definitions from the dom
  const loadCanvas = document.getElementById('loadCanvas');
  this.loadCanvas = loadCanvas;
  const loadctx = loadCanvas.getContext('2d');
  this.loadctx = loadctx;

  /* ---------------------------------------------------------------- */

  // defineMarginPadding() - here we declare the variable items that change where cards are rendered on the screen, and how big they are.
  this.defineMarginPadding = function (client) {
    // here we determine the size of the screen and it's ratio (landscape/portrait, etc);
    // depending on these we set dynamic values for the screen padding and card data
    let ratio = (client.layout.width / client.layout.height).toFixed(2);
    let offset;
    if (client.playerDevice == 'mobile') {
      offset = 0.26;
    } else {
      //any desktop
      offset = 0.15;
    }
    if (client.cardChoice == 'accessibility') {
      // want this to override the mobile option.
      offset = 0.27;
    }
    if (client.cardChoice === 'accessibility1') {
      // want this to override the mobile option
      offset = 0.3;
    }

    const xsmall = {
      paddingX: 40,
      cardMargin: 8.5,
      topYpadding: 40,
      cardYpadding: offset,
      screenYpadding: 18.5,
      cardWidth: 50
    };
    const small = {
      paddingX: 40,
      cardMargin: 8.5,
      topYpadding: 40,
      cardYpadding: offset,
      screenYpadding: 18.5,
      cardWidth: 80
    };
    const medium = {
      paddingX: 40,
      cardMargin: 8.5,
      topYpadding: 40,
      cardYpadding: offset,
      screenYpadding: 18.5,
      cardWidth: 108
    };
    const large = {
      paddingX: 40,
      cardMargin: 39.5,
      topYpadding: 40,
      cardYpadding: offset,
      screenYpadding: 49.5,
      cardWidth: 150
    };

    let calcWidth = (client.layout.calcWidth); // have to consider the dpr for measurements to be correct

    if (calcWidth >= 500 && calcWidth < 725) {
      return xsmall;
    } else if (calcWidth >= 725 && calcWidth < 960) {
      return small;
    } else if (calcWidth >= 960 && calcWidth < 1440) {
      return medium;
    } else if (calcWidth >= 1440) {
      return large;
    }
  };

  /* ---------------------------------------------------------------- */

  // do a quick logic check (applies to screen resize functionality) to see if the player has switched from mobile to desktop or vice versa. Change buttons on nav bar accordingly.

  this.setPlayerDevice = function (client) {
    let dpr = window.devicePixelRatio;
    const { layout } = client;

    canvas.style.width = `${document.documentElement.clientWidth}px`;
    canvas.style.width.replace('px', '');

    canvas.style.height = `${document.documentElement.clientHeight}px`;
    canvas.style.height.replace('px', '');

    canvas.width = document.documentElement.clientWidth;
    canvas.height = document.documentElement.clientHeight;

    const canvasInfo = canvas.getBoundingClientRect();

    // WIDTH AND HEIGHT DEFINITIONS: CANVAS

    layout.width = Math.round(parseInt(canvasInfo.width) * dpr);
    layout.height = Math.round(parseInt(canvasInfo.height) * dpr);

    layout.calcWidth = layout.width / dpr;
    layout.calcHeight = layout.height / dpr;

    // subtract the nav bar / tabs offset for safari / ios layout issue. window.innerWidth and height is glitchy for ios / safari users.

    document.body.style.width = layout.calcWidth;
    document.body.style.height = layout.calcHeight;

    if ((layout.calcWidth <= 499 && layout.calcWidth >= 200 && layout.calcHeight <= 940 && layout.calcHeight >= 0) || (layout.calcWidth >= 499 && layout.calcWidth <= 940 && layout.calcHeight <= 499 && layout.calcHeight >= 0)) {

      // do another check to see if we are just going mobile to mobile. If so, don't adjust the close icon. It's already where it should be.

      client.playerDevice = 'mobile';

      let closeButton = document.querySelector('#btn-alerts');

      const buttonContainer = document.querySelector('.button-container-desktop');

      if (buttonContainer.children[0].classList.contains('close-button')) {
        buttonContainer.removeChild(closeButton);
      }

      const closeIconMobile = document.querySelector('.close-icon-mobile');

      if (!closeIconMobile.hasChildNodes()) { // if the close icon section doesn't have the x, let's add it!
        closeIconMobile.appendChild(closeButton);
      }

    } else {
      // if (client.playerDevice == 'mobile') {
      client.playerDevice = 'desktop';

      // find the close button
      let closeButton = document.querySelector('#btn-alerts');

      // remove it from the top left corner, if it's there.
      const closeIconMobile = document.querySelector('.close-icon-mobile');
      if (closeIconMobile.hasChildNodes()) {

        // now add it back into the desktop button container, if it isn't there.
        const buttonContainer = document.querySelector('.button-container-desktop');
        if (!buttonContainer.children[0].classList.contains('close-button')) {
          buttonContainer.insertBefore(closeIconMobile.children[0], buttonContainer.children[0]);
        }
      }
    }
  };

  /* ---------------------------------------------------------------- */

  this.setLayout = function (client) {
    const dpr = window.devicePixelRatio;
    const { layout } = client;

    if (client.playerDevice == 'mobile') {
      return this.setMobileLayout(client);
    } // exit early for mobile layout

    // WIDTH AND HEIGHT DEFINITIONS: CANVAS

    canvas.width = layout.width;
    canvas.height = layout.height;
    canvas.style.width = `${layout.calcWidth}px`;
    canvas.style.height = `${layout.calcHeight}px`;

    backCanvas.width = layout.width;
    backCanvas.height = layout.height;
    backCanvas.style.width = `${layout.calcWidth}px`;
    backCanvas.style.height = `${layout.calcHeight}px`;

    animCanvas.width = layout.width;
    animCanvas.height = layout.height;
    animCanvas.style.width = `${layout.calcWidth}px`;
    animCanvas.style.height = `${layout.calcHeight}px`;

    loadCanvas.width = layout.width;
    loadCanvas.height = layout.height;
    loadCanvas.style.width = `${layout.calcWidth}px`;
    loadCanvas.style.height = `${layout.calcHeight}px`;

    document.body.style.width = `${layout.calcWidth}px`;
    document.body.style.height = `${layout.calcHeight}px`;

    // DYNAMIC PADDING AND MARGIN BASED ON SCREEN RATIO

    let dynamicValues = this.defineMarginPadding(client);
    let { paddingX, cardMargin, cardYpadding, topYpadding, screenYpadding, cardWidth } = dynamicValues;

    // WIDTH AND PADDING ASSIGNMENTS: CARDS. See setCardPositions() for card X Position assignment.

    let paddingAllotment = layout.width - (((cardWidth * 7) + (cardMargin * 6)) * dpr); // width of screen minus card widths and margins
    let leftXpadding = (paddingAllotment / 2) - 65; // divide paddingAllotment evenly, give 65 to right side for nav
    layout.cardWidth = cardWidth * dpr; // does NOT include the margin outside of the card.
    layout.cardXpadding = cardMargin * dpr;

    // HEIGHT AND paddingASSIGNMENTS: CARDS. See setCardPositions() for card Y Position assignment.
    layout.cardHeight = layout.cardWidth * 1.4; // increase for a taller card
    layout.topYpadding = topYpadding * dpr; //factors in padding based on screen size.
    layout.cardYpadding = cardYpadding * layout.cardHeight;
    layout.screenYpadding = screenYpadding;

    // X POSITION ASSIGNMENTS: STOCK, WASTE, FOUNDATION, TABLEAU (layout.placement positions).
    layout.stockXpos = leftXpadding; // tableau and stock share the same x position (far left)
    layout.tableauXpos = leftXpadding;
    layout.wasteXpos = leftXpadding + layout.cardWidth + layout.cardXpadding; // waste is after one card and x padding
    layout.foundationXpos = leftXpadding + 3 * (layout.cardWidth + layout.cardXpadding); // foundation is after 3 cards and x paddings

    // Y POSITION ASSIGNMENTS: STOCK, WASTE, FOUNDATION, TABLEAU (layout.placement positions).

    layout.stockYpos = layout.topYpadding; // stock, waste, and foundation share the same y position (top row of screen)
    layout.wasteYpos = layout.topYpadding;
    layout.foundationYpos = layout.topYpadding;
    layout.tableauYpos = layout.topYpadding + layout.cardHeight + layout.screenYpadding; // tableau is on the second row
    return layout;
  };

  /* ---------------------------------------------------------------- */

  //  Mobile device padding, margins, and initial layout (stock, waste, tableau, foundation)

  this.setMobileLayout = function (client) {

    const { layout } = client;

    const dpr = window.devicePixelRatio;

    // WIDTH AND HEIGHT DEFINITIONS: CANVAS
    if (window.matchMedia('(orientation: portrait)').matches && (layout.calcWidth < layout.calcHeight)) {

      console.log('Portrait Mode');
      document.body.style.width = `${layout.calcHeight}px`;
      document.body.style.height = `${layout.calcWidth}px`;

      let newWidth = layout.height;
      let newHeight = layout.width;
      layout.width = newWidth;
      layout.height = newHeight;
      layout.calcWidth = newWidth / dpr;
      layout.calcHeight = newHeight / dpr;
      // Update modals to be 100% of doc width. Has to be done here, html has wrong dimensions on phone flip (square)
      let modalClass = document.getElementsByClassName('modal');

      for (let i = 0; i < modalClass.length; i++) {
        modalClass[i].style.width = `${layout.calcWidth}px!important`;
        modalClass[i].style.height = `${layout.calcHeight}px!important`;
      }

    } else {
      document.body.style.width = `${layout.calcWidth}px`;
      document.body.style.height = `${layout.calcHeight}px`;
    }

    // define the 4 canvas width and heights
    canvas.width = layout.width;
    canvas.height = layout.height;
    canvas.style.width = `${layout.calcWidth}px`;
    canvas.style.height = `${layout.calcHeight}px`;

    backCanvas.width = layout.width;
    backCanvas.height = layout.height;
    backCanvas.style.width = `${layout.calcWidth}px`;
    backCanvas.style.height = `${layout.calcHeight}px`;

    animCanvas.width = layout.width;
    animCanvas.height = layout.height;
    animCanvas.style.width = `${layout.calcWidth}px`;
    animCanvas.style.height = `${layout.calcHeight}px`;

    loadCanvas.width = layout.width;
    loadCanvas.height = layout.height;
    loadCanvas.style.width = `${layout.calcWidth}px`;
    loadCanvas.style.height = `${layout.calcHeight}px`;

    // WIDTH AND PADDING ASSIGNMENTS: CARDS. See setCardPositions() for card X Position assignment.

    let leftXpadding = 90 * dpr;
    let totalPadding = (leftXpadding * 2); //will give us equal 5% left and right padding.
    let availWidth = layout.width - totalPadding;

    layout.cardWidth = 50 * dpr; // does NOT include the margin outside of the card.
    let cardSpace = layout.cardWidth * 7; // 50px * 7 cards
    let totalMargin = availWidth - cardSpace; //total margin, avail width less the 350 px it takes for 7 cards.
    layout.cardXpadding = totalMargin / 8; // grace given each card for margin, so when drawn side by side the paddingis there.

    // HEIGHT AND PADDING ASSIGNMENTS: CARDS. See setCardPositions() for card Y Position assignment.

    let availHeight = layout.height - (40 * dpr);
    layout.cardHeight = 70 * dpr; // increase for a taller card
    let cardYSpace = 280 * dpr;
    let totalYMargin = availHeight - cardYSpace;

    layout.topYpadding = 20 * dpr; //factors in padding based on screen size.
    layout.cardYpadding = totalYMargin / 4; // gives us the gap between the three foundation cards.
    layout.foundationYpadding = totalYMargin / 4;
    layout.screenYpadding = layout.height - (20 * dpr);

    // X POSITION ASSIGNMENTS: STOCK, WASTE, FOUNDATION, TABLEAU (layout.placement positions).

    layout.stockXpos = 20 * dpr; // tableau and stock share the same x position (far left)
    layout.tableauXpos = leftXpadding + layout.cardXpadding;
    layout.wasteXpos = 20 * dpr; // waste is after one card and x padding
    layout.foundationXpos = layout.width - (70 * dpr);// foundation is far right, with 50px card width and 20px margin

    // Y POSITION ASSIGNMENTS: STOCK, WASTE, FOUNDATION, TABLEAU (layout.placement positions).

    layout.stockYpos = 20 * dpr; // stock, waste, and foundation share the same y position (top row of screen)
    layout.wasteYpos = (20 * dpr) + layout.cardHeight + (15 * dpr); //to account for stock and 5 card shadow
    layout.foundationYpos = 20 * dpr;
    layout.tableauYpos = 20 * dpr; // tableau is on the second row
    layout.tableauYpadding = Math.max(availHeight / 16, (17.5 * dpr));
    return layout;

  };

  /* ---------------------------------------------------------------- */

  // Sets our mobile card properties based on their array location for future rendering.

  this.setMobileCardPositions = function (client) {

    const dpr = window.devicePixelRatio;

    const { stock, waste, tableau, foundation } = client.placement;
    const layout = client.layout;

    // stock cards - x and y position assignments.

    stock.forEach(function (card) {
      card.xpos = layout.stockXpos;
      card.ypos = layout.stockYpos;
    });

    // waste cards - x and y position assignments.

    waste.forEach(function (card) {
      card.xpos = layout.wasteXpos;
      card.ypos = layout.wasteYpos;
    });

    // waste cards for 'deal 3 - KLONDIKE MODE', need x offset position added for cards 2 and 3.

    if (client.gameMode == client.GAMEMODE.KLONDIKE) {

      let offset = 30 * dpr;

      if (client.wasteCardCount == 3 && waste.length >= 3) {

        const thirdCard = waste[waste.length - 3];
        thirdCard.ypos = layout.wasteYpos;

        const secondCard = waste[waste.length - 2];
        secondCard.ypos += offset;

        const topCard = waste[waste.length - 1];
        topCard.ypos += offset * 2;

      }

      if (client.wasteCardCount == 2 && waste.length >= 2) {
        const secondCard = waste[waste.length - 2];
        secondCard.ypos = layout.wasteYpos;

        const topCard = waste[waste.length - 1];
        topCard.ypos += offset;
      }

      if (client.wasteCardCount == 3 && waste.length == 2) {
        client.wasteCardCount == 2;

        const secondCard = waste[waste.length - 2];
        secondCard.ypos = layout.wasteYpos;

        const topCard = waste[waste.length - 1];
        topCard.ypos += offset;
      }

      if ((client.wasteCardCount == 3 || client.wasteCardCount == 2) && waste.length == 1) {
        client.wasteCardCount == 1;
      }
    }

    Object.values(foundation).forEach((foundColumn, i) => {

      // each of the four foundations has a different y point on mobile (vertical)
      if (foundColumn.length) {
        for (let j = 0; j < foundColumn.length; j++) {
          foundColumn[j].xpos = layout.foundationXpos;
          foundColumn[j].ypos = layout.foundationYpos + (i * (layout.foundationYpadding + layout.cardHeight));
        }
      }
    });

    // tableau cards - x and y position assignments (X and Y change based on which of the 7 tableau columns we are rendering and which place the card falls in each tableau columns' array.)
    let counter = 0;

    Object.values(tableau).forEach((tab, i) => {
      // to find the length of longest tab array
      counter = Math.max(counter, tab.length);
    });

    Object.values(tableau).forEach((card, i) => {
      let xpos = layout.tableauXpos + i * (layout.cardXpadding + layout.cardWidth);
      // 1st array is starting width, next column adds another card/x padding

      for (let j = 0; j < card.length; j++) {
        card[j].xpos = xpos;
        card[j].ypos = layout.tableauYpos + j * layout.cardYpadding;

        if (layout.width > layout.height) { // landscape mode, rules to prevent cards stacking off screen
          let heightAllotment = layout.height - layout.tableauYpos - layout.cardHeight;
          let eachCardPadding = Math.min((heightAllotment / counter), (.3 * layout.cardHeight));
          card[j].ypos = layout.tableauYpos + j * eachCardPadding;

          if (layout.cardYpadding < eachCardPadding) {
            // to prevent click+hold shrinking padding behavior on non-accessibility cards
            layout.cardYpadding = eachCardPadding;
          }
        }
      }
    });
  };

  /* ---------------------------------------------------------------- */

  // create a main loading screen, make sure it overrides the other canvases.

  this.loadingCanvas = function (layout, SOL_Render) {

    document.querySelector('#loadCanvas').style.zIndex = 5;
    let time = 1;
    let timerInterval = setInterval(function () {
      let percentage = (time / 60) * 100;
      SOL_Render.renderLoadScreen(layout, percentage, true);
      time++;
      if (time > 60) {
        clearInterval(timerInterval);
        SOL_Render.renderLoadScreen(layout, percentage, false);
        document.querySelector('#loadCanvas').style.zIndex = 0;
      }
    }, 30);

  };

  /* ---------------------------------------------------------------- */
  // creates empty arrays as placeholders for waste, stock, foundation, and tableau. Included in render loop.

  this.createEmptyArrays = function (client) {

    const { layout } = client;
    // have to clear previous slots or these will simply ADD items when called on resize();
    client.emptyStockSlot = {};
    client.emptyWasteSlot = {};
    client.emptyFoundationSlots = [];
    client.emptyTableauSlots = [];

    client.emptyStockSlot = {
      // stock slot - empty card
      name: 'emptyStock',
      value: 1,
      xpos: layout.stockXpos,
      ypos: layout.stockYpos,
    };

    client.emptyWasteSlot = {
      // waste slot - empty card
      name: 'emptyWaste',
      value: 1,
      xpos: layout.wasteXpos,
      ypos: layout.wasteYpos,
    };

    if (client.playerDevice == 'desktop') { // foundation is horizontal on desktop

      for (let i = 0; i < 4; i++) { // foundation slots - 4 empty cards
        let slot = {
          name: `emptyFound${i + 1}`,
          value: i + 1,
          xpos: layout.foundationXpos + (i * (layout.cardWidth + layout.cardXpadding)),
          ypos: layout.foundationYpos,
        };

        client.emptyFoundationSlots.push(slot);
      }
    } else { // if on mobile, take them vertical

      for (let i = 0; i < 4; i++) { // foundation slots - 4 empty cards
        let slot = {
          name: `emptyFound${i + 1}`,
          value: i + 1,
          xpos: layout.foundationXpos,
          ypos: layout.foundationYpos + (i * (layout.cardHeight + layout.cardYpadding)),
        };

        client.emptyFoundationSlots.push(slot);
      }

    }

    for (let i = 0; i < 7; i++) {
      // tableau slots - 7 empty cards
      let slot = {
        name: `emptyTab${i + 1}`,
        value: i + 1,
        xpos:
          layout.tableauXpos + i * (layout.cardWidth + layout.cardXpadding),
        ypos: layout.tableauYpos,
      };
      client.emptyTableauSlots.push(slot);
    }

  };
  /* ---------------------------------------------------------------- */

  // Sets our card properties based on their array location for future rendering.

  this.setCardPositions = function (client) {

    const dpr = window.devicePixelRatio;

    if (client.playerDevice == 'mobile') {
      return this.setMobileCardPositions(client);
    }

    const { stock, waste, tableau, foundation } = client.placement;
    const layout = client.layout;

    // stock cards - x and y position assignments.
    stock.forEach(function (card) {
      card.xpos = layout.stockXpos;
      card.ypos = layout.stockYpos;
    });

    // waste cards - x and y position assignments.
    waste.forEach(function (card) {
      card.xpos = layout.wasteXpos;
      card.ypos = layout.wasteYpos;
    });

    // waste cards for 'deal 3 - KLONDIKE MODE', need x offset position added for cards 2 and 3.

    if (client.gameMode == client.GAMEMODE.KLONDIKE) {

      let offset;

      if (client.playerDevice == 'mobile') {

        offset = 30 * dpr;

        if (client.wasteCardCount == 3 && waste.length >= 3) {

          const thirdCard = waste[waste.length - 3];
          thirdCard.ypos = layout.wasteYpos;

          const secondCard = waste[waste.length - 2];
          secondCard.ypos += offset;

          const topCard = waste[waste.length - 1];
          topCard.ypos += offset * 2;

        }

        if (client.wasteCardCount == 2 && waste.length >= 2) {
          const secondCard = waste[waste.length - 2];
          secondCard.ypos = layout.wasteYpos;

          const topCard = waste[waste.length - 1];
          topCard.ypos += offset;
        }

        if (client.wasteCardCount == 3 && waste.length == 2) {
          client.wasteCardCount == 2;

          const secondCard = waste[waste.length - 2];
          secondCard.ypos = layout.wasteYpos;

          const topCard = waste[waste.length - 1];
          topCard.ypos += offset;
        }

        if ((client.wasteCardCount == 3 || client.wasteCardCount == 2) && waste.length == 1) {
          client.wasteCardCount == 1;
        }
      } else {

        offset = layout.cardWidth * .15;

        if (client.cardChoice == 'accessibility') {
          offset = layout.cardWidth * .3;
        }
        if (client.cardChoice == 'accessibility1') {
          offset = layout.cardWidth * .4;
        }
      }

      if (client.wasteCardCount == 3 && waste.length >= 3) {

        const thirdCard = waste[waste.length - 3];
        thirdCard.xpos = layout.wasteXpos;

        const secondCard = waste[waste.length - 2];
        secondCard.xpos += offset;

        const topCard = waste[waste.length - 1];
        topCard.xpos += offset * 2;

      }

      if (client.wasteCardCount == 2 && waste.length >= 2) {
        const secondCard = waste[waste.length - 2];
        secondCard.xpos = layout.wasteXpos;

        const topCard = waste[waste.length - 1];
        topCard.xpos += offset;
      }

      if (client.wasteCardCount == 3 && waste.length == 2) {
        client.wasteCardCount == 2;

        const secondCard = waste[waste.length - 2];
        secondCard.xpos = layout.wasteXpos;

        const topCard = waste[waste.length - 1];
        topCard.xpos += offset;
      }

      if ((client.wasteCardCount == 3 || client.wasteCardCount == 2) && waste.length == 1) {
        client.wasteCardCount == 1;
      }
    }

    // foundation cards - x and y position assignments (X changes based on which of the 4 foundation columns we are rendering)

    Object.values(foundation).forEach((foundColumn, i) => {

      // each of the four foundations has a different x point.
      if (foundColumn.length) {
        for (let j = 0; j < foundColumn.length; j++) {
          foundColumn[j].xpos =
            layout.foundationXpos +
            i * (layout.cardXpadding + layout.cardWidth);
          foundColumn[j].ypos = layout.foundationYpos;
        }
      }
    });

    // tableau cards - x and y position assignments (X and Y change based on which of the 7 tableau columns we are rendering and which place the card falls in each tableau columns' array.)
    let counter = 0;

    Object.values(tableau).forEach((tab, i) => {
      // to find the length of longest tab array
      counter = Math.max(counter, tab.length);
    });

    Object.values(tableau).forEach((card, i) => {
      let xpos = layout.tableauXpos + i * (layout.cardXpadding + layout.cardWidth);
      // 1st array is starting width, next column adds another card/x padding

      for (let j = 0; j < card.length; j++) {
        card[j].xpos = xpos;
        card[j].ypos = layout.tableauYpos + j * layout.cardYpadding;

        if (layout.width > layout.height) { // landscape mode, rules to prevent cards stacking off screen
          let heightAllotment = layout.height - layout.tableauYpos - layout.cardHeight;
          let eachCardPadding = Math.min((heightAllotment / counter), (.3 * layout.cardHeight));
          card[j].ypos = layout.tableauYpos + j * eachCardPadding;

          if (layout.cardYpadding < eachCardPadding) {
            // to prevent click+hold shrinking padding behavior on non-accessibility cards
            layout.cardYpadding = eachCardPadding;
          }
        }
      }
    });

  };

  /* ---------------------------------------------------------------- */

  // Updates our layout and redraws our canvas/cards/background when a user resizes their viewport.

  this.resize = function (client, SOL_Render) {
    this.setLayout(client); // set the layout based on new screen size
    this.createEmptyArrays(client);
    this.setCardPositions(client); // set the new card x/y values based on new layout. Placement stays the same, tableau cards still belong in tableau, for example.
    bctx.clearRect(0, 0, client.layout.width, client.layout.height); // clear current screen and background
    ctx.clearRect(0, 0, client.layout.width, client.layout.height);
    SOL_Render.renderBackground(client.layout); // re-render background
  };

}
