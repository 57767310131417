/*
 *  SOL_Undo.js
 *  Solitaire
 *
 *  Created by Kieran Hannagan on Sept 5, 2022
 *  Copyright © 2022-2023 Sinclair Digital. - All Rights Reserved
 *
 *  This file contains functions that handle the state of the game and the undo button
 */
/* ---------------------------------------------------------------- */

export function SOL_Undo(SOL_Object) {

  this.client = SOL_Object.client;                                        // keep a copy of client for use in this object
  const client = this.client;


  let undoStack = [];                                                     // Create a placeholder array for all moves to be undone (or not?)
  this.undoStack = undoStack;                                             // make public

  /* ---------------------------------------------------------------- */

  // pushState() - Push the current state of the game onto the undo stack.

  // We take the current state of the game and build an object with just the card index
  // values.  That object includes the stock, foundation, tableau and waste arrays.

  // What's great about this is we can easily serialize this data into a JSON blob and
  // send it up to the cloud for Multiplayer games and spectator viewing.

  // The top of the undo stack is always the same as the current game being viewed.  This
  // makes it much easier to do validation and debugging on the undo system.


  // this.pushState = function () {
  this.pushState = function (newGame = false) {
    let { stock, waste, foundation, tableau } = client.placement;        // Deconstruct objects from client for ease of access
    if (newGame) {
      undoStack.length = 0;
    }

    const currentMove = {
      stock: [],
      foundation: {                                                      // 4 separate placements for each foundation
        fnd1: [],
        fnd2: [],
        fnd3: [],
        fnd4: [],
      },
      tableau: {                                                        // 7 separate placements for each tableau
        tab1: [],
        tab2: [],
        tab3: [],
        tab4: [],
        tab5: [],
        tab6: [],
        tab7: [],
      },
      waste: [],
    };

    function copyCardNumsInArray(dst, src, cnt) {                       // Custom function for copying just the cardNum
      for (let i = 0; i < cnt; i++) {
        const cardNum = src[i].cardNum + 1;
        if (src[i].up) {
          dst[i] = cardNum;
        } else {
          dst[i] = -cardNum;
        }
      }
    }

    copyCardNumsInArray(currentMove.stock, stock, stock.length);

    copyCardNumsInArray(currentMove.waste, waste, waste.length);

    copyCardNumsInArray(currentMove.foundation.fnd1, foundation.fnd1, foundation.fnd1.length);
    copyCardNumsInArray(currentMove.foundation.fnd2, foundation.fnd2, foundation.fnd2.length);
    copyCardNumsInArray(currentMove.foundation.fnd3, foundation.fnd3, foundation.fnd3.length);
    copyCardNumsInArray(currentMove.foundation.fnd3, foundation.fnd4, foundation.fnd4.length);

    copyCardNumsInArray(currentMove.tableau.tab1, tableau.tab1, tableau.tab1.length);
    copyCardNumsInArray(currentMove.tableau.tab2, tableau.tab2, tableau.tab2.length);
    copyCardNumsInArray(currentMove.tableau.tab3, tableau.tab3, tableau.tab3.length);
    copyCardNumsInArray(currentMove.tableau.tab4, tableau.tab4, tableau.tab4.length);
    copyCardNumsInArray(currentMove.tableau.tab5, tableau.tab5, tableau.tab5.length);
    copyCardNumsInArray(currentMove.tableau.tab6, tableau.tab6, tableau.tab6.length);
    copyCardNumsInArray(currentMove.tableau.tab7, tableau.tab7, tableau.tab7.length);

    undoStack.push(currentMove);
  };
  /* ---------------------------------------------------------------- */

  // lookup card object
  function lookupCard(cardNum) {
    for (let i = 0; i < client.cards.length + 1; i++) {
      const normalizeCardNum = cardNum - 1;
      if (client.cards[i].cardNum == normalizeCardNum) {
        return client.cards[i];
      } // return card object that correlates to the cardNum
      // code should never reach here
    }
  }

  /* ---------------------------------------------------------------- */

  // removing the current state from the game state stack

  this.popState = function (resetGame = false) {
    let { stock, waste, foundation, tableau } = client.placement;                       // Deconstruct objects from client for ease of access

    // reverse the logic of push and take out the lastMove from the stack
    if (!undoStack.length) {
      return;
    }
    if (resetGame) {
      undoStack.length = 2;
    }
    undoStack.pop();                                                                    // we simply toss the current state

    const prevState = undoStack[undoStack.length - 1];                                  // we set the table to the previous state

    let newStock = [];                                                                  // build a new array called newStock
    for (let i = 0; i < prevState.stock.length; i++) {
      newStock.push(lookupCard(Math.abs(prevState.stock[i])));
      newStock[newStock.length - 1].up = prevState.stock[i] > 0 ? true : false;
    }
    client.placement.stock = newStock;

    let newWaste = [];                                                                  // build a new array called newWaste
    for (let i = 0; i < prevState.waste.length; i++) {
      newWaste.push(lookupCard(Math.abs(prevState.waste[i])));
      newWaste[newWaste.length - 1].up = prevState.waste[i] > 0 ? true : false;
    }
    client.placement.waste = newWaste;

    let newFnd1 = [];                                                                   // build a new array called newFnd1
    for (let i = 0; i < prevState.foundation.fnd1.length; i++) {
      newFnd1.push(lookupCard(Math.abs(prevState.foundation.fnd1[i])));
      newFnd1[newFnd1.length - 1].up = prevState.foundation.fnd1[i] > 0 ? true : false;
    }
    client.placement.foundation.fnd1 = newFnd1;

    let newFnd2 = [];                                                                   // build a new array called newFnd2
    for (let i = 0; i < prevState.foundation.fnd2.length; i++) {
      newFnd2.push(lookupCard(Math.abs(prevState.foundation.fnd2[i])));
      newFnd2[newFnd2.length - 1].up = prevState.foundation.fnd2[i] > 0 ? true : false;
    }
    client.placement.foundation.fnd2 = newFnd2;

    let newFnd3 = [];                                                                   // build a new array called newFnd3
    for (let i = 0; i < prevState.foundation.fnd3.length; i++) {
      newFnd3.push(lookupCard(Math.abs(prevState.foundation.fnd3[i])));
      newFnd3[newFnd3.length - 1].up = prevState.foundation.fnd3[i] > 0 ? true : false;
    }
    client.placement.foundation.fnd3 = newFnd3;

    let newFnd4 = [];                                                                    // build a new array called newFnd4
    for (let i = 0; i < prevState.foundation.fnd4.length; i++) {
      newFnd4.push(lookupCard(Math.abs(prevState.foundation.fnd4[i])));
      newFnd4[newFnd4.length - 1].up = prevState.foundation.fnd4[i] > 0 ? true : false;
    }
    client.placement.foundation.fnd4 = newFnd4;

    let newTab1 = [];                                                                     // build a new array called newTab1
    for (let i = 0; i < prevState.tableau.tab1.length; i++) {
      newTab1.push(lookupCard(Math.abs(prevState.tableau.tab1[i])));
      newTab1[newTab1.length - 1].up = prevState.tableau.tab1[i] > 0 ? true : false;
    }
    client.placement.tableau.tab1 = newTab1;

    let newTab2 = [];                                                                     // build a new array called newTab2
    for (let i = 0; i < prevState.tableau.tab2.length; i++) {
      newTab2.push(lookupCard(Math.abs(prevState.tableau.tab2[i])));
      newTab2[newTab2.length - 1].up = prevState.tableau.tab2[i] > 0 ? true : false;
    }
    client.placement.tableau.tab2 = newTab2;

    let newTab3 = [];                                                                     // build a new array called newTab3
    for (let i = 0; i < prevState.tableau.tab3.length; i++) {
      newTab3.push(lookupCard(Math.abs(prevState.tableau.tab3[i])));
      newTab3[newTab3.length - 1].up = prevState.tableau.tab3[i] > 0 ? true : false;
    }
    client.placement.tableau.tab3 = newTab3;

    let newTab4 = [];                                                                     // build a new array called newTab4
    for (let i = 0; i < prevState.tableau.tab4.length; i++) {
      newTab4.push(lookupCard(Math.abs(prevState.tableau.tab4[i])));
      newTab4[newTab4.length - 1].up = prevState.tableau.tab4[i] > 0 ? true : false;
    }
    client.placement.tableau.tab4 = newTab4;

    let newTab5 = [];                                                                     // build a new array called newTab5
    for (let i = 0; i < prevState.tableau.tab5.length; i++) {
      newTab5.push(lookupCard(Math.abs(prevState.tableau.tab5[i])));
      newTab5[newTab5.length - 1].up = prevState.tableau.tab5[i] > 0 ? true : false;
    }
    client.placement.tableau.tab5 = newTab5;

    let newTab6 = [];                                                                     // build a new array called newTab6
    for (let i = 0; i < prevState.tableau.tab6.length; i++) {
      newTab6.push(lookupCard(Math.abs(prevState.tableau.tab6[i])));
      newTab6[newTab6.length - 1].up = prevState.tableau.tab6[i] > 0 ? true : false;
    }
    client.placement.tableau.tab6 = newTab6;

    let newTab7 = [];                                                                     // build a new array called newTab7
    for (let i = 0; i < prevState.tableau.tab7.length; i++) {
      newTab7.push(lookupCard(Math.abs(prevState.tableau.tab7[i])));
      newTab7[newTab7.length - 1].up = prevState.tableau.tab7[i] > 0 ? true : false;
    }
    client.placement.tableau.tab7 = newTab7;

    //TODO: we will compute all the things we need to animate here
  };

  // this.resetState = function (undoStack) {
  //   return undoStack = [];
  // };
}

