/* ---------------------------------------------------------------- */
/*
 *  scoringUtils.js
 *  Solitaire
 *
 *  Created by Kieran Hannagan & Kim Moran on Aug 18, 2022
 *  Copyright © 2022 Sinclair Digital. - All Rights Reserved
 *
 *  Utility functions for scoring and move validations
 */
/* ---------------------------------------------------------------- */

export function SOL_Score(client) {

  this.createLocalScoreObject = function () {

    const stats = {
      gameCount: 0,
      //    POINTS
      pointsCurrent: 0,
      pointsBest: 0,
      pointsAverage: 0,
      pointsTotal: 0,

      //    TIME
      timeCurrent: 0,
      secondsBest: 0,
      secondsAverage: 0,
      secondsTotal: 0,
      secondsPlayed: 0,
      timeBest: 0,
      timeAverage: '00:00',
      timeTotal: '00:00:00',

      //    MOVES
      movesCurrent: 0,
      movesBest: 0,
      movesAverage: 0,
      movesTotal: 0,
      //    WIN STATS
      wins: 0,
      winPercentage: 0,
      winStreakCount: 0,
      topWinStreak: 0,
      winStreak: false,
      winTimeBest: '00:00',
      winTimeAverage: '00:00',
      winTimeTotal: '00:00',
      winMovesCurrent: 0,
      winMovesBest: 0,
      winMovesAverage: 0,
      winMovesTotal: 0,
      winPointsAverage: 0,
      winPointsTotal: 0,
      winSecondsBest: 0,
      winSecondsAverage: 0,
      winSecondsTotal: 0,

      //    LOSS STATS
      losses: 0,
      lossPercentage: 0,
      // lossStreakCount: 0,
      // lossStreak: false,

    };

    localStorage.setItem('stats', JSON.stringify(stats));

  };

  /* ---------------------------------------------------------------- */

  this.calculateScore = function () {
    if (client.gameState == client.GAMESTATE.WON) {
      return;
    }
    let points = 0; //subtract 35 for the starting 7 up tableau cards. Don't count toward your score.
    let tableauCount = 0;
    for (let i = 0; i < 52; i++) {
      const card = client.cards[i];

      if (card.isOnTableau && card.up) {
        tableauCount++;
      }
      if (card.isOnFoundation) {
        points += 10;
      }
    }


    if (tableauCount >= 8) {
      points += (tableauCount - 7) * 5; // we can only count the 8th tableau card and up for points. The first seven are dealt to us already up.
    }
    if (client.gameMode == client.GAMEMODE.STANDARD) {
      if (client.deckFlip >= 1) {
        points += client.deckFlip * -100;
      }
    } else {
      if (client.deckFlip >= 4) {
        points += client.deckFlip * -20;
      }
    }
    points = Math.max(points, 0); // if points are negative, score is zero.

    return points;
  };

  /* ---------------------------------------------------------------- */

  // calculate final score

  this.calculateWinningScore = function (points) {
    if (client.gameState != client.GAMESTATE.WON) {
      return;
    }
    // All cards on stock, foundation, and tableau at win time now get an additional 10 points because they are placed on the foundation.

    let timeMultiplier = (client.secondsPlayed / 10) * 2; // time multiplier, -2 for every 10 seconds that elapse
    client.points -= timeMultiplier;

    // get counts of all the cards on the foundation and subtract it from total cards (52).
    let count = 0;
    for (let i = 0; i < 52; i++) {
      if (client.cards[i].isOnFoundation && client.cards[i].up) {
        count++;
      }
    }

    client.points += ((52 - count) * 10);
    return Math.max(client.points, 0); // score should not go negative

  };

  /* ---------------------------------------------------------------- */

  // check for win, counts foundation cards, returns boolean.

  this.checkForWin = function () {
    // let tableauUp = true;

    let tableauUp = false;
    if (((client.placement.foundation.fnd1.length == 12) && (client.placement.foundation.fnd2.length == 12) && (client.placement.foundation.fnd3.length == 12) && (client.placement.foundation.fnd4.length == 12))) {
      // trigger win sequence and other updates.

      client.gameState = client.GAMESTATE.WON;
      return true;
    } else {
      for (let i = 0; i < 52; i++) {
        // if we find a card on the tableau that is face down, return false/early exit.
        if (client.cards[i].isOnTableau && !client.cards[i].up) {
          return false;
        } else {
          tableauUp = true;
        }
      }
    }

    if (tableauUp) {
      client.gameState = client.GAMESTATE.WON;
      return true;
    }
  };
}
